import { Row, Col, Card, Flex } from "antd";
import classes from "./Dashboard.module.css";
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
const DataCards = (props) => {
  return (
    <Row gutter={16}>
      <Col lg={12} md={12} xs={24}>
        <Card
          style={{
            backgroundColor: "#daebf336", // Light sky blue with 0.9 opacity
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Link
                to="/gate/transaction/inwardGatePass?inward"
                style={{ textDecoration: "none" }}
              >
                <PlusCircleOutlined
                  style={{
                    fontSize: "24px",
                    color: "#45b6fe",
                    marginRight: "8px",
                  }}
                />
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Add Inward
                </span>
              </Link>
            </Col>
          </Row>
          <Row>
            <Row style={{ width: "100%" }}>
              <Col
                lg={18}
                md={16}
                xs={14}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                    whiteSpace: "nowrap", // Prevents text from wrapping
                  }}
                >
                  Month To Date Inward Gate Pass
                </span>{" "}
              </Col>
              <Col
                lg={6}
                md={8}
                xs={10}
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Aligns number to the right
                  alignItems: "center",
                }}
              >
                {/* Heading */}
                <div
                  className={classes["Rata1"]}
                  style={{ textAlign: "right" }}
                >
                  {props.data.TotalSaudaMonthlyValue !== null
                    ? props.data.TotalSaudaMonthlyValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>

            <Row style={{ width: "100%" }}>
              <Col
                lg={18}
                md={16}
                xs={14}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                    whiteSpace: "nowrap", // Prevents text from wrapping
                  }}
                >
                  Year To Date Inward Gate Pass
                </span>{" "}
              </Col>
              <Col
                lg={6}
                md={8}
                xs={10}
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Aligns number to the right
                  alignItems: "center",
                }}
              >
                {/* Heading */}
                <div
                  className={classes["Rata1"]}
                  style={{
                    // color: "white", // Keep existing white color for the text
                    padding: "2px", // Add some padding for better appearance
                  }}
                >
                  {props.data.TotalSaudaYearlyValue !== null
                    ? props.data.TotalSaudaYearlyValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>

            <Row style={{ width: "100%" }}>
              <Col
                lg={18}
                md={16}
                xs={14}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "17px",
                    fontWeight: 600,
                  }}
                >
                  Current Pending Inward Gate Pass
                </span>{" "}
                {/* Heading */}
              </Col>
              <Col
                lg={6}
                md={8}
                xs={10}
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Aligns number to the right
                  alignItems: "center",
                }}
              >
                <div className={classes["Rata1"]}>
                  {props.data.TotalPendingSaudaValue !== null
                    ? props.data.TotalPendingSaudaValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>
          </Row>
        </Card>
      </Col>

      <Col lg={12} md={12} xs={24}>
        <Card
          style={{
            background: "#daebf336",
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Link
                to="/gate/transaction/outwardgatepass?outward"
                style={{ textDecoration: "none" }}
              >
                <PlusCircleOutlined
                  style={{
                    fontSize: "24px",
                    color: "#45b6fe",
                    marginRight: "8px",
                  }}
                />
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Add Outward
                </span>
              </Link>
            </Col>
          </Row>
          <Row>
            <Row style={{ width: "100%" }}>
              <Col
                lg={18}
                md={16}
                xs={14}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                    whiteSpace: "nowrap", // Prevents text from wrapping
                    // overflow: "hidden",
                  }}
                >
                  Month To Date Total Outward Gate Pass
                </span>{" "}
                {/* Heading */}
              </Col>
              <Col
                lg={6}
                md={8}
                xs={10}
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Aligns number to the right
                  alignItems: "center",
                }}
              >
                <div className={classes["Rata1"]}>
                  {props.data.TotalMonthlyOrderValue !== null
                    ? props.data.TotalMonthlyOrderValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>

            <Row style={{ width: "100%" }}>
              <Col
                lg={18}
                md={16}
                xs={14}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                    whiteSpace: "nowrap", // Prevents text from wrapping
                  }}
                >
                  Year To Date Outward Gate Pass
                </span>{" "}
                {/* Heading */}
              </Col>
              <Col
                lg={6}
                md={8}
                xs={10}
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Aligns number to the right
                  alignItems: "center",
                }}
              >
                <div
                  className={classes["Rata1"]}
                  style={{
                    // color: "white", // Keep existing white color for the text
                    padding: "2px", // Add some padding for better appearance
                  }}
                >
                  {props.data.TotalYearlyOrderValue !== null
                    ? props.data.TotalYearlyOrderValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default DataCards;
