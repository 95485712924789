import React, { useRef, useState } from "react";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import axios from "axios"; // Import axios
import classes from "../Pages.module.css";
import DataField from "./DataField";
import ReqCodeModal from "./ReqcodeModel"; // Import the modal component
import CustomerModal from "./CustomerItem";

const BasicDetail = (props) => {
  // console.log(props, "dtata");
  const [modalData, setModalData] = useState([]); // State to store data for the modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalForApiVisible, setIsModalForApiVisible] = useState(false);
  const [codalData, setCodalData] = useState([]);
  const planDateRef = useRef(null);
  const requirementDateRef = useRef(null);
  const currencyrate = useRef(null);
  const type = useRef(null);
  const currency = useRef(null);
  const ordertype = useRef(null);
  const partyref = useRef(null);
  const poRef = useRef(null);
  const reqtype = useRef(null);

  const handleChange = async (e, param) => {
    if (param === "gate_pass") {
      setIsModalVisible(true);
      // Nullify purreqDetail when req_code changes
      props.setData((data) => {
        const newdata = [...data.mrirHdr];
        newdata[0][param] = e;

        return {
          ...data,
          mrirHdr: newdata,
          mrirDetail: null, // Set purreqDetail to null
        };
      });
      try {
        props.onReqCodeChange(e);
        // API call using axios
        const response = await axios.get(
          `http://sastetab.com/api/v1/purchaseorder/purchase_order_with_requisition/${e}`,
          { withCredentials: true }
        );

        // console.log("API Response:", response.data.data.customer.rows);

        setModalData(response.data.data.customer.rows);

        // Update the state with the API response
        props.setData((data) => {
          const newdata = [...data["mrirHdr"]];
          newdata[0][param] = e;

          return {
            ...data,
            poHdr: newdata,
            mrirHdr: response.data.data.customer.rows, // Store the API response here
          };
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed (e.g., set an error state or show a message)
      }
    }

    props.setData((data) => {
      const newdata = [...data["mrirHdr"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        mrirHdr: newdata,
      };
    });
  };

  const handleSChange = async (val, param) => {
    if (param === "gate_pass") {
      // Nullify purreqDetail when req_code changes
      props.setData((data) => {
        const newdata = [...data.mrirHdr];
        newdata[0][param] = val;

        return {
          ...data,
          mrirHdr: newdata,
          mrirDetail: null, // Set purreqDetail to null
        };
      });
      try {
        props.onReqCodeChange(val);
        // API call using axios
        const response = await axios.get(
          `http://sastetab.com/api/v1/purchaseorder/purchase_order_with_requisition/${val}`,
          { withCredentials: true }
        );

        // console.log("API Response:", response.data.data.customer.rows);
        setIsModalVisible(true);
        setModalData(response.data.data.customer.rows);

        // Update the state with the API response
        props.setData((data) => {
          const newdata = [...data["poHdr"]];
          newdata[0][param] = val;

          return {
            ...data,
            poHdr: newdata,
            purreqDetail: response.data.data.customer.rows, // Store the API response here
          };
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed (e.g., set an error state or show a message)
      }
    } else if (param === "m_type") {
      // Handle type change
      props.setData((data) => {
        const newdata = [...data["mrirHdr"]];
        newdata[0][param] = val;

        // If the new type is "w", set req_code to null
        if (val === "w" && data.mrirHdr[0].gate_pass) {
          newdata[0]["gate_pass"] = null;
        }

        return {
          ...data,
          mrirHdr: newdata,
          mrirDetail: [
            {
              item_code: null,
              size_code: null,
              hsn: null,
              quality_code: null,
              store_cd: null,
              item_acc_code: null,
              ordered_qty: null,
              deal_rate: null,
              discount: null,
              amount: null,
              dis_type: null,
              deal_rate1: null,
              con_qty: null,
              con_uom: null,
              gross_p: null,
              received_qty: null,
              amt: null,
              remark: null,
              h_tax: null,
            },
          ],
        };
      });
    } else {
      // If param is not "req_code", just update the state without API call
      props.setData((data) => {
        const newdata = [...data["mrirHdr"]];
        newdata[0][param] = val;

        return {
          ...data,
          mrirHdr: newdata,
        };
      });
    }
  };

  const handleChangegross = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["mrirHdr"]];
      // console.log(data);
      newdata[0][param] = e.target.value;
      props.data.received_weight =
        data.mrirHdr[0].grass_weight - data.mrirHdr[0].taire_weight;

      //    props.data['salesInvoice'][0]['net_wt'] = data['salesInvoice'][0]['gross_wt'] - data['salesInvoice'][0]['tare_wt']

      // console.log(newdata);
      return {
        ...data,
        mrirHdr: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["mrirHdr"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        mrirHdr: newdata,
      };
    });
  };

  const handleReqCodeClick = async () => {
    try {
      // Make the API call when Req Code is clicked
      const response = await axios.get(
        `http://sastetab.com/api/v1/mrir/mrir-with-gate-pass`,
        { withCredentials: true }
      );

      // console.log("API Response:", response.data.data.Requisition.rows);

      // Update the modal data state with the API response
      setModalData(response.data.data.Requisition.rows);

      // Show the modal
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleModalRowClick = async (record) => {
    console.log("Selected Row:", record);

    // Construct the query parameters
    const params = {
      factory_arrival_code: record.factory_arrival_code,
      unit_code: record.unit_code,
      dealtype_cd: record.dealtype_cd,
    };

    try {
      // Send the GET request with the query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/mrir/mrir-with-gate-pass/additional-data/`,
        { params, withCredentials: true }
      );

      console.log("API Response:", response.data.data.Requisition.rows);

      // Optionally handle the response data here

      // Update the state with the selected value
      props.setData((data) => {
        const newdata = [...data["mrirHdr"]];
        newdata[0]["gate_pass"] = record.factory_arrival_code;
        newdata[0]["deal_code"] = record.dealtype_cd;
        newdata[0]["party_code"] = record.party_code;
        newdata[0]["truck_no"] = record.truck_no;

        return {
          ...data,
          mrirHdr: newdata,
          mrirDetail: response.data.data.Requisition.rows,
        };
      });
    } catch (error) {
      console.error("Error fetching additional data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleButtonClick = async () => {
    try {
      console.log(props.data);
      // Destructure the required values from props
      const { gate_pass, deal_code } = props.data;

      // Make the API call, passing dealercode and customer_code as query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/mrir/additional-data-of-mrir-with-mrn?reqCode=${gate_pass}& dealType=${deal_code}`,
        { withCredentials: true }
      );

      // Log the API response for debugging
      console.log("API Response:", response.data.data.Requisition.rows);

      // Update the modal data state with the API response
      setCodalData(response.data.data.Requisition.rows);

      // Show the modal
      setIsModalForApiVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleApiModalRowClick = async (record) => {
    console.log(record);
    props.setData((data) => {
      return {
        ...data,

        mrirDetail: [...data.mrirDetail, record],
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            name="Requisition Code"
            param="REQ_CODE"
            value={props.data.req_code}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          options={props.ad.TYPE}
          handleChange={handleSChange}
          type="Select"
          id="type"
          required="True"
          name="Gate Pass Type"
          param="m_type"
          value={props.data.m_type}
          disabled={props.editmrMode1}
          rules={[{ message: "missing name" }]}
          // ref={type}
          // onNextFocus={() => planDateRef.current.focus()}
        />

        {props.data.m_type !== "w" && (
          <DataField
            editMode={props.editMode}
            lg={4}
            md={24}
            id="type"
            required="True"
            name="Gate No"
            param="gate_pass"
            onClick={handleReqCodeClick} // Add onClick handler here as well
            value={props.data.gate_pass}
            disabled={props.editmrMode1}
            rules={[{ message: "missing name" }]}
            // ref={reqtype}
            // onNextFocus={() => planDateRef.current.focus()}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          options={props.ad.DEAL_CODE}
          handleChange={handleSChange}
          type="Select"
          id="deal_code"
          required="True"
          name="DealType"
          param="deal_code"
          value={props.data.deal_code}
          rules={[{ message: "missing name" }]}
          // ref={planDateRef}
          // onNextFocus={() => requirementDateRef.current.focus()}
        />

        {/* <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          options={props.ad.DEAL_CODE}
          handleChange={handleSChange}
          type="Select"
          id="deal_code"
          required="True"
          name="Deal Type"
          param="deal_code"
          value={props.data.deal_code}
          rules={[{ message: "missing name" }]}
          // ref={requirementDateRef}
          // onNextFocus={() => currency.current.focus()}
        /> */}

        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleSChange}
          options={props.ad.PARTY_CODE}
          type="Select"
          name="Vendor"
          id="party_code"
          required="True"
          param="party_code"
          value={props.data.party_code}
          // ref={currency}
          // onNextFocus={() => currencyrate.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          options={props.ad.CURRENCY_CD}
          handleChange={handleSChange}
          type="Select"
          id="currency_cd"
          required="True"
          name="Currency"
          param="currency_cd"
          value={props.data.currency_cd}
          rules={[{ message: "missing name" }]}
          // ref={requirementDateRef}
          // onNextFocus={() => currency.current.focus()}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Currency Rate"
          required="True"
          type="InputNumber"
          id="currency_rate1"
          param="currency_rate1"
          value={props.data.currency_rate1}
          // ref={currencyrate}
          // onNextFocus={() => partyref.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          type="Date"
          id="mrir_date"
          required="True"
          name="Mrir Date"
          param="mrir_date"
          value={props.data.mrir_date}
          rules={[{ message: "missing name" }]}
          // ref={planDateRef}
          // onNextFocus={() => requirementDateRef.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          type="Date"
          id="bill_date"
          name="Bill Date"
          param="bill_date"
          value={props.data.bill_date}
          rules={[{ message: "missing name" }]}
          // ref={planDateRef}
          // onNextFocus={() => requirementDateRef.current.focus()}
        />
        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleChange}
          name="Bill No"
          id="bill_no"
          param="bill_no"
          value={props.data.bill_no}
          // ref={currencyrate}
          // onNextFocus={() => partyref.current.focus()}
        />
        {/* 
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Bill No"
          required="True"
          id="bill_no"
          param="bill_no"
          value={props.data.bill_no}
          // ref={currencyrate}
          // onNextFocus={() => partyref.current.focus()}
        /> */}

        {/* <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleSChange}
          options={props.ad.PARTY_CODE}
          type="Select"
          name="Party"
          required="True"
          id="party_code"
          param="party_code"
          value={props.data.party_code}
          ref={partyref}
          onNextFocus={() => poRef.current.focus()}
        /> */}

        {/*
        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleChange}
          name="PO Ref"
          required="True"
          id="po_ref"
          param="po_ref"
          value={props.data.po_ref}
          ref={poRef}
          onNextFocus={() => ordertype.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleSChange}
          options={props.ad.ORDER_TYPE}
          type="Select"
          name="Order Type"
          id="po_type1"
          required="True"
          param="po_type1"
          value={props.data.po_type1}
          ref={ordertype}
          // onNextFocus={() => requestedByRefrate.current.focus()}
        /> */}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Truck No"
          required="True"
          id="truck_no"
          param="truck_no"
          value={props.data.truck_no}
          // ref={currencyrate}
          // onNextFocus={() => partyref.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChangegross}
          name="Gross Wt."
          type="InputNumber"
          id="grass_weight"
          param="grass_weight"
          value={props.data.grass_weight}
          // ref={currencyrate}
          // onNextFocus={() => partyref.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChangegross}
          name="Tare Wt."
          type="InputNumber"
          id="taire_weight"
          param="taire_weight"
          value={props.data.taire_weight}
          // ref={currencyrate}
          // onNextFocus={() => partyref.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Our Wt."
          id="received_weight"
          param="received_weight"
          value={props.data.received_weight}
          disabled={true}
          // ref={currencyrate}
          // onNextFocus={() => partyref.current.focus()}
        />
        <Col span={5} style={{ marginRight: "16px" }}>
          {/* Adjusted span for better width distribution */}
          <div
            style={{
              marginBottom: "8px",
              marginTop: "2px",
              // border: "2px solid red",
            }}
          >
            {/* Container for margin */}
            <label
              style={{
                display: "block",
                marginBottom: "15px",
                fontSize: "10px", // Smaller font size
                fontWeight: "bold", // Bold text
                color: "#333", // Darker color, you can choose any color
              }}
            >
              NET QTY.
            </label>
            <input
              type="text"
              value={props.netQty}
              readOnly
              style={{
                width: "90%", // Ensure input takes full width of column
                padding: "5px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                textAlign: "right",
                boxSizing: "border-box", // Include padding and border in element's total width and height
              }}
            />
          </div>
        </Col>
        <Col span={4}>
          {/* Adjusted span for better width distribution */}
          <div style={{ marginBottom: "8px", marginTop: "2px" }}>
            {/* Container for margin */}
            <label
              style={{
                display: "block",
                marginBottom: "15px",
                fontSize: "10px", // Smaller font size
                fontWeight: "bold", // Bold text
                color: "#333",
              }}
            >
              NET AMOUNT
            </label>
            <input
              type="text"
              value={props.netAmount}
              readOnly
              style={{
                width: "90%", // Ensure input takes full width of column
                padding: "5px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                textAlign: "right",
                backgroundColor: "#ffffff",
                boxSizing: "border-box", // Include padding and border in element's total width and height
              }}
            />
          </div>
        </Col>

        {props.data.m_type !== "w" && (
          <Button
            type="primary"
            onClick={handleButtonClick}
            style={{ marginLeft: "15px", marginTop: "30px" }}
          >
            Add item
          </Button>
        )}
      </Row>

      <ReqCodeModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onRowClick={handleModalRowClick} // Function to handle when a row in the modal is clicked
        data={modalData} // Pass the data to be displayed in the modal
      />

      <CustomerModal
        title="API Data Modal"
        visible={isModalForApiVisible}
        width={900}
        onRowClick={handleApiModalRowClick}
        onClose={() => setIsModalForApiVisible(false)}
        // onCancel={handleApiModalClose}
        data1={codalData}
      />
    </div>
  );
};

export default BasicDetail;
