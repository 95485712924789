import React, { useState } from "react";
import {
  Table,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  message,
  Button,
} from "antd";
import axios from "axios";
import jsPDF from "jspdf";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Ledger = ({ columns, account, apiEndpoint }) => {
  const [filteredData, setFilteredData] = useState([]); // Table data
  const [readOnlyValue, setReadOnlyValue] = useState(""); // Opening balance
  const [selectedAccount, setSelectedAccount] = useState(null); // Selected account
  const [loading, setLoading] = useState(false); // Loading state
  const [dateRange, setDateRange] = useState([null, null]); // Start and End Dates

  // Function to transform and calculate running balance
  const transformData = (siteList, openingBalance) => {
    const transformedData = siteList.rows.map((row) => ({
      voucher_date: row.voucher_date,
      voucher_code: row.voucher_code,
      account_name: row.account_name,
      account_code: row.account_code,
      narration: row.narration || "No description",
      debit: row.entry_type === "D" ? parseFloat(row.amount) : 0,
      credit: row.entry_type === "C" ? parseFloat(row.amount) : 0,
    }));

    let runningBalance = parseFloat(openingBalance.amount) || 0;
    let balanceType = openingBalance.type || "DR";

    const dataWithBalance = transformedData.map((entry) => {
      const { debit, credit } = entry;

      if (balanceType === "CR") {
        if (debit > 0) {
          runningBalance -= debit;
          if (runningBalance < 0) {
            runningBalance = Math.abs(runningBalance);
            balanceType = "DR";
          }
        } else {
          runningBalance += credit;
        }
      } else if (balanceType === "DR") {
        if (credit > 0) {
          runningBalance -= credit;
          if (runningBalance < 0) {
            runningBalance = Math.abs(runningBalance);
            balanceType = "CR";
          }
        } else {
          runningBalance += debit;
        }
      }

      return {
        ...entry,
        balance: {
          amount: Math.abs(runningBalance),
          type: balanceType,
        },
      };
    });

    return dataWithBalance;
  };

  // Fetch data from API
  const fetchLedgerData = async (accountCode, startDate, endDate) => {
    if (!startDate || !endDate) return;

    setFilteredData([]);
    setReadOnlyValue("");
    setLoading(true);

    try {
      const response = await axios.get(
        "http://sastetab.com/api/v1/controldata/additional-data-of-ledger-opening",
        {
          params: {
            account_code: accountCode,
            start_date: startDate,
            end_date: endDate,
          },
          withCredentials: true,
        }
      );

      const openingBalance = {
        amount: Math.abs(
          response.data.data.ACCOUNT_CODEH.rows[0].opening_balance
        ),
        type:
          response.data.data.ACCOUNT_CODEH.rows[0].opening_balance < 0
            ? "CR"
            : "DR",
      };

      const dataWithBalance = transformData(
        response.data.data.site_list,
        openingBalance
      );

      setFilteredData(dataWithBalance);
      setReadOnlyValue(openingBalance);
    } catch (error) {
      message.error("Failed to fetch ledger data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAccountChange = (value) => {
    setSelectedAccount(value);

    if (!dateRange || !dateRange[0] || !dateRange[1]) {
      setDateRange([null, null]);
      setReadOnlyValue(0);
      setFilteredData([]);
      return;
    }

    if (value) {
      fetchLedgerData(value, dateRange[0], dateRange[1]);
    } else {
      setFilteredData([]);
    }
  };

  const handleDateFilter = (dates) => {
    setDateRange(dates);

    if (selectedAccount && dates && dates[1]) {
      const [startDate, endDate] = dates;
      fetchLedgerData(
        selectedAccount,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
    }
  };

  // PDF Generation
  const generatePDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Ledger Report", 14, 20);

    doc.setFontSize(12);
    doc.text(`Account: ${selectedAccount || "N/A"}`, 14, 30);
    doc.text(
      `Date Range: ${dateRange[0]?.format("YYYY-MM-DD") || "N/A"} to ${
        dateRange[1]?.format("YYYY-MM-DD") || "N/A"
      }`,
      14,
      40
    );
    doc.text(
      `Opening Balance: ${readOnlyValue.amount || "0.00"} ${
        readOnlyValue.type || "N/A"
      }`,
      14,
      50
    );

    const headers = [
      "Date",
      "Voucher Code",
      "Account Name",
      "Debit",
      "Credit",
      "Balance",
    ];
    const headerX = [14, 40, 80, 120, 150, 180];
    let currentY = 60;

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    headers.forEach((header, index) => {
      doc.text(header, headerX[index], currentY);
    });

    currentY += 10;

    doc.setFont("helvetica", "normal");
    filteredData.forEach((row) => {
      if (currentY > 280) {
        doc.addPage();
        currentY = 20;
      }

      const rowData = [
        row.voucher_date,
        row.voucher_code,
        row.account_name,
        row.debit.toFixed(2),
        row.credit.toFixed(2),
        `${row.balance.amount.toFixed(2)} ${row.balance.type}`,
      ];

      rowData.forEach((data, colIndex) => {
        doc.text(String(data), headerX[colIndex], currentY);
      });

      currentY += 8;
    });

    doc.save("Ledger_Report.pdf");
  };

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <div style={{}}>
            <label
              htmlFor="account-select"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "bold",
              }}
            >
              Account
            </label>
            <Select
              id="account-select"
              placeholder="Select Account"
              onChange={handleAccountChange}
              style={{ width: "100%" }}
              value={selectedAccount}
            >
              {account.rows.map((row) => (
                <Option value={row.account_code} key={row.account_code}>
                  {row.account} {/* Displaying account name as label */}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div style={{}}>
            <label
              htmlFor="account-select"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "bold",
              }}
            >
              Date
            </label>
            <RangePicker onChange={handleDateFilter} allowClear={true} />
          </div>
        </Col>
        <Col span={6}>
          <div style={{}}>
            <label
              htmlFor="account-select"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "bold",
              }}
            >
              Openning Balance
            </label>
            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: "8px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                minHeight: "32px",
                // display: "flex",
                alignItems: "center",
              }}
            >
              {readOnlyValue && (
                <span
                  style={{
                    color: readOnlyValue.type === "CR" ? "green" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {readOnlyValue.amount} {readOnlyValue.type}
                </span>
              )}
            </div>
          </div>
        </Col>
        <Col span={4}>
          <div style={{ marginTop: "25px" }}>
            <label
              htmlFor="account-select"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "bold",
              }}
            ></label>
            <Button type="primary" onClick={generatePDF}>
              Download PDF
            </Button>
          </div>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="voucher_code"
        loading={loading}
        pagination={{ pageSize: 10 }}
        summary={(pageData) => {
          let totalDebit = 0;
          let totalCredit = 0;

          pageData.forEach(({ debit, credit }) => {
            totalDebit += debit || 0;
            totalCredit += credit || 0;
          });

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <strong style={{ color: "red" }}>
                  {totalDebit.toFixed(2)}
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <strong style={{ color: "green" }}>
                  {totalCredit.toFixed(2)}
                </strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} />
            </Table.Summary.Row>
          );
        }}
      />
    </div>
  );
};

export default Ledger;
