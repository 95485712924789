import classes from "./SiderContentC.module.css";
import { Menu, message } from "antd";
// import { FaSignOutAlt } from "react-icons/fa";
import signInSignUpLogo from "../../../assets/signInSignUp.png";
import { MdDashboard } from "react-icons/md";

import { Link } from "react-router-dom";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { MdAssignmentTurnedIn } from "react-icons/md";
import {
  FaSignOutAlt,
  FaFileInvoice,
  FaReceipt,
  FaHandsHelping,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import { GiSellCard } from "react-icons/gi";

const { SubMenu } = Menu;

const SiderContentS = (props) => {
  const history = useNavigate();

  const handleLogout = (event) => {
    axios
      .get(props.url + "/api/v1/users/logout", {
        withCredentials: true,
      })
      .then((response) => {
        message.success({
          content: "Goodbye!!!! See You Again!!!",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        history("/erp");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <>
      <img
        className={classes["SignInSignUpLogo"]}
        src={signInSignUpLogo}
        alt="Logo"
      />
      <Menu
        mode="inline"
        defaultSelectedKeys={["empDashboard"]}
        // defaultOpenKeys={["misc"]}
        style={{
          height: "100%",
          borderRight: 0,
          overflowY: "auto",
        }}
        theme="light"
      >
        <Menu.Item key="empDashboard" icon={<MdDashboard />}>
          <Link to="/purchase/dashboard" style={{ textDecoration: "none" }}>
            Dashboard
          </Link>
        </Menu.Item>

        <SubMenu key="Registers" title="Registers" icon={<FaFileInvoice />}>
          <Menu.Item key="indent-register">
            <Link
              to="/purchase/indent-register"
              style={{ textDecoration: "none" }}
            >
              Purchase Indent Register
            </Link>
          </Menu.Item>
          <Menu.Item key="ind-register">
            <Link
              to="/purchase/purchase-order-register"
              style={{ textDecoration: "none" }}
            >
              Purchase Order Register
            </Link>
          </Menu.Item>
          <Menu.Item key="mrir-register">
            <Link
              to="/purchase/mrir-register"
              style={{ textDecoration: "none" }}
            >
              Mrir Register
            </Link>
          </Menu.Item>

          <Menu.Item key="pending-indent-register">
            <Link
              to="/purchase/pending-indent-register"
              style={{ textDecoration: "none" }}
            >
              Pending Indent Register
            </Link>
          </Menu.Item>
          <Menu.Item key="pending-po-gate-pass-register">
            <Link
              to="/purchase/pending-po-gate-pass-register"
              style={{ textDecoration: "none" }}
            >
              Pending Po for Gate Pass Register
            </Link>
          </Menu.Item>
          <Menu.Item key="pending-po-mrir-register">
            <Link
              to="/purchase/get-pending-po-mrir"
              style={{ textDecoration: "none" }}
            >
              Pending Po for Mrir Register
            </Link>
          </Menu.Item>
        </SubMenu>

        {/* <SubMenu
          key="pending-Registers"
          title="pending-Registers"
          icon={<GiSellCard />}
        >
          
        </SubMenu> */}

        <SubMenu key="master-purchase" title="Masters" icon={<FaReceipt />}>
          <Menu.Item key="vender-masterr">
            <Link
              to="/purchase/transactions/vender-master"
              style={{ textDecoration: "none" }}
            >
              Vender Master
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="transactions"
          title="Transactions"
          icon={<MdAssignmentTurnedIn />}
        >
          {/* <Menu.Item key="vendorMaster">
            <Link
              to="/purchase/transactions/vender-master"
              style={{ textDecoration: "none" }}
            >
              Vender Master
            </Link>
          </Menu.Item> */}

          <Menu.Item key="purchaseIndent">
            <Link
              to="/purchase/transactions/purchase-indent"
              style={{ textDecoration: "none" }}
            >
              Purchase Indent
            </Link>
          </Menu.Item>

          <Menu.Item key="Salesformorder">
            <Link
              to="/purchase/transaction/purchase-order"
              style={{ textDecoration: "none" }}
            >
              Purchase Order
            </Link>
          </Menu.Item>

          <Menu.Item key="MrirDetail">
            <Link
              to="/purchase/transaction/mrir-detail"
              style={{ textDecoration: "none" }}
            >
              MRIR
            </Link>
          </Menu.Item>

          <Menu.Item key="dailyIssue">
            <Link
              to="/purchase/transaction/PurchaseReturn/Purchasereturnview"
              style={{ textDecoration: "none" }}
            >
              Purchase Return
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item
          key="empLogout"
          onClick={(event) => handleLogout(event)}
          icon={<FaSignOutAlt style={{ color: "red", opacity: "0.7" }} />}
        >
          <Link to="/erp" style={{ textDecoration: "none" }}>
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default SiderContentS;
