import React, { useEffect, useState } from "react";
import axios from "axios";
import Ledger from "./Ledger";
import classes from "./Page.module.css";

const columns = [
  {
    title: "Account Name",
    dataIndex: "account_name",
    key: "account_name",
  },

  {
    title: "Opening Value",
    dataIndex: "opening_value",
    key: "opening_value",
    render: (value) => (
      <span>
        {Math.abs(parseFloat(value)).toFixed(2)}{" "}
        <strong>{parseFloat(value) >= 0 ? "Dr" : "Cr"}</strong>
      </span>
    ),
  },

  {
    title: "Transactions", // Parent Header for Transactions
    children: [
      {
        title: "Debit Value",
        dataIndex: "dr_value",
        key: "dr_value",
        render: (debit) => (
          <span className={classes["debit-value"]}>
            {parseFloat(debit).toFixed(2)}
          </span>
        ),
      },
      {
        title: "Credit Value",
        dataIndex: "cr_value",
        key: "cr_value",
        render: (credit) => (
          <span className={classes["credit-value"]}>
            {parseFloat(credit).toFixed(2)}
          </span>
        ),
      },
    ],
  },

  {
    title: (
      <div
        style={{
          textAlign: "center",
          marginRight: "calc(10vw + 50px)", // Adjust dynamically based on viewport width
        }}
      >
        Closing Balance
      </div>
    ),
    children: [
      {
        title: "Debit",
        key: "closing_balance_debit",
        render: (_, record) => {
          const balance = parseFloat(record.closing_balance);
          return balance > 0 ? balance.toFixed(2) : "-";
        },
      },
      {
        title: "Credit",
        key: "closing_balance_credit",
        render: (_, record) => {
          const balance = parseFloat(record.closing_balance);
          return balance < 0 ? Math.abs(balance).toFixed(2) : "-";
        },
      },
      {
        title: "Net Closing Balance",
        dataIndex: "closing_balance",
        key: "net_closing_balance",
        render: (balance) => (
          <span>
            {Math.abs(parseFloat(balance)).toFixed(2)}{" "}
            <strong>{parseFloat(balance) >= 0 ? "Dr" : "Cr"}</strong>
          </span>
        ),
      },
    ],
  },
];

const App = () => {
  const [ledgerData, setLedgerData] = useState([]);
  const [account, setAccount] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://sastetab.com/api/v1/controldata/additional-data-of-trail-opening",
          {
            withCredentials: true,
          }
        );
        console.log(response, "API Response");

        const { ACCOUNT_CODEH, site_list } = response.data.data; // default to empty array if no ledger data
        console.log(site_list, "this is good to see");
        // Set account data
        setAccount(ACCOUNT_CODEH);
        setLedgerData(site_list.rows);
      } catch (error) {
        console.error("Error fetching additional data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <p style={{ color: "#1560BD", fontWeight: "bold", fontSize: "25px" }}>
        Trail Balance
      </p>
      {account?.rows?.length ? (
        <Ledger data={ledgerData} columns={columns} account={account} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default App;
