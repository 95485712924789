import React from "react";
import { Modal, Table } from "antd";

const ReqCodeModal = ({ visible, onClose, onRowClick, data }) => {
  const columns = [
    { title: "Order No", dataIndex: "booking_code", key: "booking_code" },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    { title: "Order Date", dataIndex: "booking_date", key: "booking_date" },
    { title: "Dealer Name", dataIndex: "dealer_nm", key: "dealer_nm" },
  ];

  return (
    <Modal
      title="Select Any Order"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={900}
      style={{ backgroundColor: "#f0f0f0" }} // Setting modal background color to light gray
      bodyStyle={{ backgroundColor: "#f0f0f0" }} // Styling the body content as well
    >
      <Table
        dataSource={data}
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => {
              onRowClick(record);
              onClose();
            },
          };
        }}
      />
    </Modal>
  );
};

export default ReqCodeModal;
