import React, { useEffect } from "react";
import { Form, Input, Select, Button } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import classes from "../Pages.module.css";
import axios from "axios";
import { useState, useContext, useRef } from "react";
import DataContext from "../../../Context/dataContext";
const { Option } = Select;

const VDetailPart = (props) => {
  const employeeData = useContext(DataContext);
  const [form] = Form.useForm();
  const { data, setData, calculateTotals } = props;

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => ({
      ...data,
      VoucherDetail: allValues.VoucherDetail,
    }));
  };

  const handlechangee1 = async (changedValues, allValues, i) => {
    console.log(i);
    const values = form.getFieldsValue(["VoucherDetail"]);
    values.VoucherDetail[i].external_entity_code = null;
    // pcsRef.current.value = null;
    // sizeRef.current.value = null;

    props.setData((data) => {
      console.log(data);
      console.log(i);
      console.log(data.VoucherDetail[i].account_code);

      axios
        .get(
          employeeData.URL +
            "/api/v1/voucher/additional-data-of-cust/ " +
            data.VoucherDetail[i].account_code,
          {
            withCredentials: true,
          }
        )

        .then((response) => {
          if (
            response.data.data.hsn1.rows &&
            response.data.data.hsn1.rows.length > 0
          ) {
            const HsnValue = response.data.data.hsn1.rows[0].distributor_code;

            // Only assign if the length condition is satisfied
            values.VoucherDetail[i].external_entity_code = HsnValue;

            console.log("HsnValue assigned:", HsnValue);
          } else {
            console.log("No rows available to assign HsnValue.");
          }
          // console.log(response);

          // const HsnValue = response.data.data.hsn1.rows[0].distributor_code;

          // values.VoucherDetail[i].external_entity_code = HsnValue;
          // form.setFieldsValue(values);
        });

      return {
        ...data,
      };
    });
  };

  const handleVoucherDetailChange = (changedValue, changedName) => {
    // Update the specific VoucherDetail field with the new value
    setData((prevData) => {
      console.log(prevData);
      const updatedVoucherDetail = prevData.VoucherDetail.map((item, index) => {
        if (index === changedName) {
          return {
            ...item,
            amount: changedValue, // Update the 'amount' field for the specific item
          };
        }
        return item;
      });

      // Calculate the new totals for credit and debit
      const { totalCr, totalDr } = calculateTotals(updatedVoucherDetail);

      // Return the updated state with the new VoucherDetail and VoucherHeader
      return {
        ...prevData,
        VoucherDetail: updatedVoucherDetail,
        VoucherHeader: [
          {
            ...prevData.VoucherHeader[0],
            cr: totalCr,
            dr: totalDr,
          },
        ],
      };
    });
  };

  return props.editMode ? (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
      form={form}
    >
      <Form.List name="VoucherDetail" initialValue={props.data}>
        {(fields, { add, remove }) => (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ padding: "4px", width: "14%" }}>
                  Entity Type{" "}
                  <span
                    style={{
                      backgroundColor: "rgb(217 217 217 / -0.65%)",
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </th>
                <th style={{ padding: "4px", width: "14%" }}>
                  Account{" "}
                  <span
                    style={{
                      backgroundColor: "rgb(217 217 217 / -0.65%)",
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </th>
                <th style={{ padding: "4px", width: "14%" }}>
                  Amount{" "}
                  <span
                    style={{
                      backgroundColor: "rgb(217 217 217 / -0.65%)",
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </th>
                <th style={{ padding: "4px", width: "14%" }}>Cost</th>
                <th style={{ padding: "4px", width: "14%" }}>Department</th>
                <th style={{ padding: "4px", width: "14%" }}>Budget</th>
                <th style={{ padding: "4px", width: "8%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <tr key={key} style={{ height: "auto" }}>
                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "entry_type"]}
                      fieldKey={[fieldKey, "entry_type"]}
                      rules={[{ required: true, message: "Missing Name" }]}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        placeholder="Select Entity Type"
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        optionFilterProp="children"
                      >
                        {props.ad.ENTRY_TYPE.rows.map((option) => (
                          <Option
                            key={option[props.ad.ENTRY_TYPE.fields[0].name]}
                            value={option[props.ad.ENTRY_TYPE.fields[0].name]}
                          >
                            {option[props.ad.ENTRY_TYPE.fields[1].name]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "account_code"]}
                      fieldKey={[fieldKey, "account_code"]}
                      rules={[{ required: true, message: "Missing Name" }]}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        placeholder="Select Account"
                        bordered={true}
                        onChange={(changedValues, allValues) =>
                          handlechangee1(changedValues, allValues, index)
                        }
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        optionFilterProp="children"
                      >
                        {props.ad.ACCOUNT_CODEH.rows.map((option) => (
                          <Option
                            key={option[props.ad.ACCOUNT_CODEH.fields[0].name]}
                            value={
                              option[props.ad.ACCOUNT_CODEH.fields[0].name]
                            }
                          >
                            {option[props.ad.ACCOUNT_CODEH.fields[1].name]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "amount"]}
                      fieldKey={[fieldKey, "amount"]}
                      rules={[
                        {
                          required: true,
                          message: "Field should not be blank!",
                        },
                        {
                          type: "number",
                          transform: (value) => Number(value),
                          message: "Please enter only numeric value",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        type="number"
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          // color: "#1777C4",
                          // fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Amount"
                        onChange={(e) =>
                          handleVoucherDetailChange(e.target.value)
                        }
                      />
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "cost_code"]}
                      fieldKey={[fieldKey, "cost_code"]}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        placeholder="Select Cost Center"
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        optionFilterProp="children"
                      >
                        {props.ad.Cost.rows.map((option) => (
                          <Option
                            key={option[props.ad.Cost.fields[0].name]}
                            value={option[props.ad.Cost.fields[0].name]}
                          >
                            {option[props.ad.Cost.fields[1].name]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "department_code"]}
                      fieldKey={[fieldKey, "department_code"]}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        placeholder="Select Department"
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        optionFilterProp="children"
                      >
                        {props.ad.Department.rows.map((option) => (
                          <Option
                            key={option[props.ad.Department.fields[0].name]}
                            value={option[props.ad.Department.fields[0].name]}
                          >
                            {option[props.ad.Department.fields[1].name]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "budget_code"]}
                      fieldKey={[fieldKey, "budget_code"]}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        placeholder="Select Budget"
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        optionFilterProp="children"
                      >
                        {props.ad.Budget.rows.map((option) => (
                          <Option
                            key={option[props.ad.Budget.fields[0].name]}
                            value={option[props.ad.Budget.fields[0].name]}
                          >
                            {option[props.ad.Budget.fields[1].name]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>

                  {/* Icons for adding/removing rows */}
                  <td style={{ textAlign: "center", paddingTop: "10px" }}>
                    <MinusCircleOutlined
                      className={classes["Remove"]}
                      onClick={() => remove(name)}
                    />
                    &nbsp;
                    <PlusCircleOutlined
                      className={classes["Add"]}
                      onClick={() => {
                        add();
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Form.List>
    </Form>
  ) : (
    props.data.map((data, index) => (
      // Display data in read-only mode
      // Add display logic for all fields here
      <>
        {/* Add more fields as needed */}
        <span>{data.entry_type}</span>
      </>
    ))
  );
};

export default VDetailPart;
