import { Row, Col, Tabs, message } from "antd";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../Context/dataContext";
import BasicDetails from "./Issueheaderdetails";
import SyncLoader from "react-spinners/SyncLoader";
import classes from "../Pages.module.css";
// import dayjs from "dayjs";
import dayjs from "dayjs";

const { TabPane } = Tabs;
const identifiers = ["VoucherDetail"];

const IssueReturnNew = () => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [deletedRows, setDeletedRows] = useState([]);
  const [allData, setAllData] = useState({
    IssueReturnHeader: [
      {
        return_date: dayjs(new Date()).format("DD-MM-YYYY"),
        issue_code: null,
        issue_date: null,
        return_code: null,
        d_code: null,
        dept_code: null,
        depttcode: null,
        dcccode: null,
      },
    ],
    IssueDetail: [],
  });
  const [ad, setAD] = useState(null);

  useEffect(() => {
    fetchAdditionalData();
  }, []);

  const fetchAdditionalData = () => {
    setLoading(true);
    axios
      .get(employeeData.URL + "/api/v1/issuereturnroute/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // // console.log(response.data);
        setAD(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching additional data:", error);
        setLoading(false);
      });
  };

  const handleSave = () => {
    let formValid = true;

    // identifiers.forEach((key) => {
    //   if (!allData[key].length) {
    //     formValid = false;
    //     message.error({
    //       content: `Empty Fields in ${key.toUpperCase()} Tab!!!`,
    //       className: "custom-class",
    //       style: {
    //         marginTop: "1vh",
    //       },
    //     });
    //   }
    // });

    if (formValid) {
      setLoading(true);

      const filteredIssueDetails = allData.IssueDetail.filter(
        (item) => !deletedRows.includes(item.key)
      );

      // console.log
      const postData = {
        ...allData,
        IssueDetail: filteredIssueDetails,
        VoucherDetail: allData.IssueDetail.map((item) => ({ ...item })),
      };
      // // console.log("asdfghjk", postData);

      axios
        .post(
          employeeData.URL + "/api/v1/issuereturnroute/create-issue",
          postData,
          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          message.success({
            content: "Return saved Succesfully!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
          history("/stock/transaction/Issuereturn/details");
        })
        .catch((error) => {
          console.error("Error creating Return:", error);
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = () => {
    setLoading(false);
    history("/stock/transaction/Issuereturn/details");
  };

  return (
    <>
      {ad ? (
        <Row className={classes.RowP}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes.Col}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    onClick={(event) => handleCancel(event)}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes.ProfileButton}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes.Col}>
            <div className={classes.EditView}>
              <Tabs
                defaultActiveKey="0"
                centered
                style={{ height: "100%" }}
                tabPosition="left"
                type="line"
                tabBarGutter="0"
              >
                <TabPane tab={<span>Issue Return Details</span>} key="0">
                  <BasicDetails
                    create={true}
                    ad={ad}
                    setData={setAllData}
                    data={allData.IssueReturnHeader[0]}
                    editMode={editMode}
                  />
                </TabPane>
                {/* Uncomment this when VDetailPart component is implemented */}
                {/* <TabPane tab={<span>Issue Detail</span>} key="1">
                  <VDetailPart
                    ad={ad}
                    setData={setAllData}
                    data={allData.IssueDetail}
                    editMode={editMode}
                  />
                </TabPane> */}
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <SyncLoader color={"rgba(255, 163, 77, 0.8)"} size={10} />
          <SyncLoader color={"rgba(255, 163, 77, 0.8)"} size={10} />
          <SyncLoader color={"rgba(255, 163, 77, 0.8)"} size={10} />
          <SyncLoader color={"rgba(255, 163, 77, 0.8)"} size={10} />
        </>
      )}
    </>
  );
};

export default IssueReturnNew;
