import React, { useState } from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import { Select, MenuItem, Box, Typography, Grid } from "@mui/material";

// Define colors for the PieChart slices
const COLORS = [
  "#1560BD", // Medium red
  "#3a7ca5", // Medium green
  "#45b6fe", // Medium blue
  "#E0E5FA", // Medium yellow
  "#50a5f1", // Medium purple
];

// Main component for rendering the chart
const Charts = ({ data, type }) => {
  console.log(data, type);
  const [chartValue, setChartValue] = useState("qty"); // Use "amount" as the default field

  // Handling dropdown change to toggle between different chart value fields
  const handleChange = (event) => {
    setChartValue(event.target.value);
  };

  // Helper function to format snake_case or underscore-separated strings
  const formatType = (str) => {
    if (!str) return "";
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join with spaces instead of underscores
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          maxWidth: 300,
          margin: "0 auto",
          padding: 0,
          borderRadius: 2,
          boxShadow: 0,
          textAlign: "center",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 0 }}
        >
          {/* <Grid item xs={12}>
            <Select
              value={chartValue}
              onChange={handleChange}
              variant="standard"
              sx={{
                mb: 2,
                width: "50%",
                color: "#1560BD",
              }}
            >
              <MenuItem value="qty">Quantity</MenuItem>
            </Select>
          </Grid> */}
          <Grid item xs={12}>
            <Typography
              variant="h7"
              align="center"
              sx={{ fontWeight: "bold", color: "#1560BD" }}
            >
              {chartValue === "amount"
                ? `Amount Distribution ${formatType(type)} Wise`
                : `Total Outward Quantity Wise`}
            </Typography>
          </Grid>
        </Grid>

        <Box
          sx={{
            padding: 0,
            borderRadius: 2,
            height: 300,
          }}
        >
          <PieChart width={250} height={250}>
            <Pie
              data={data}
              dataKey="qty" // Use qty as the data key
              nameKey="item"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={({ name, value }) => `${name}: ${value}`} // Show name and value directly
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </Box>
      </Box>
    </div>
  );
};

export default Charts;
