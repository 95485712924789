import React, { useEffect, useState } from "react";
import axios from "axios";
import Ledger from "./Ledger";

const columns = [
  { title: "Voucher Date", dataIndex: "voucher_date", key: "voucher_date" },
  { title: "Voucher Code", dataIndex: "voucher_code", key: "voucher_code" },
  { title: "Account Name", dataIndex: "account_name", key: "account_name" },
  { title: "Narration", dataIndex: "narration", key: "narration" },
  {
    title: "Debit",
    dataIndex: "debit",
    key: "debit",
    render: (debit) => (
      <span style={{ color: "red" }}>{debit.toFixed(2)} </span>
    ),
  },
  {
    title: "Credit",
    dataIndex: "credit",
    key: "credit",
    render: (credit) => (
      <span style={{ color: "green" }}>{credit.toFixed(2)} </span>
    ),
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: ({ amount, type }) => (
      <span style={{ color: type === "DR" ? "red" : "green" }}>
        {amount.toFixed(2)} {type}
      </span>
    ),
  },
];

const App = () => {
  const [ledgerData, setLedgerData] = useState([]);
  const [account, setAccount] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://sastetab.com/api/v1/controldata/additional-data-of-ledger",
          {
            withCredentials: true,
          }
        );
        console.log(response, "API Response");

        const { ACCOUNT_CODEH, ledger = [] } = response.data.data; // default to empty array if no ledger data

        // Set account data
        setAccount(ACCOUNT_CODEH);

        // Map the ledger data to the required format
        const transformedData = ledger.map((row) => ({
          voucher_date: row.voucher_date,
          voucher_code: row.voucher_code,
          account_name: row.account_name,
          account_code: row.account_code,
          narration: row.narration || "No description",
          debit: row.entry_type === "D" ? parseFloat(row.amount) : 0,
          credit: row.entry_type === "C" ? parseFloat(row.amount) : 0,
        }));

        // Calculate the running balance with DR/CR type
        let runningBalance = 0;
        const dataWithBalance = transformedData.map((entry) => {
          runningBalance += entry.debit - entry.credit;
          const balanceType = runningBalance >= 0 ? "DR" : "CR";
          return {
            ...entry,
            balance: {
              amount: Math.abs(runningBalance),
              type: balanceType,
            },
          };
        });

        setLedgerData(dataWithBalance);
      } catch (error) {
        console.error("Error fetching additional data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <p style={{ color: "#1560BD", fontWeight: "bold", fontSize: "25px" }}>
        Ledger
      </p>
      {account?.rows?.length ? (
        <Ledger data={ledgerData} columns={columns} account={account} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default App;
