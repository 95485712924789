import { Row } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import axios from "axios";

const BasicDetail = (props) => {
  console.log(props);
  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["chargeHeader"]];
      newdata[0][param] = e.target.value;
      //  // console.log(newdata);
      return {
        ...data,
        chargeHeader: newdata,
      };
    });
  };

  const handleSChange = async (val, param) => {
    console.log(val);

    // Send the GET request with the query parameters

   
    props.setData((data) => {
      const newdata = [...data["chargeHeader"]];
      // newdata[0][param] = charge_code;

      newdata[0][param] = val;
      return {
        ...data,
        chargeHeader: newdata,
      };
    });
  };
  
  // const handleDChange = (date, dateString, param) => {
  //   props.setData((data) => {
  //     const newdata = [...data["chargeHeader"]];
  //     newdata[0][param] = dateString;
  //     return {
  //       ...data,
  //       chargeHeader: newdata,
  //     };
  //   });
  // };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
 
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="Charge NAME"
          param="charge_desc"
          required={true}
          value={props.data.charge_desc}
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad}
          type="Select"
          name="PRIMARY ACCOUNT"
          param="primary_account"
          required={true}
          newacc="parent_code"
          value={props.data.primary_account}
          // value={props.data.trans_type}
        />
        <DataField  
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.TRANS_TYPE}
          type="Select"
          name="TRANS TYPE"
          param="trans_type"
          value={props.data.trans_type}
        />
        
      </Row>
    </div>
  );
};

export default BasicDetail;
