import React, { useState, useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import "./JournalVoucher.css";

const JournalVoucher = (props) => {
  const [data, setData] = useState(props.pdfResponse);
  const componentRef = useRef();
  const actionRef = useRef();

  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const response = await fetch(`http://localhost:3001/voucher-details/${voucher_code}`);
  //             const data = await response.json();
  //             setData(data);

  //         } catch (err) {
  //             console.log('Failed to fetch purchase order data', err);
  //         }
  //     };
  //     fetchData();
  // }, [voucher_code]);
  const numberToWords = (num) => {
    const a = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    const numToWords = (n) => {
      if (n < 20) return a[n];
      if (n < 100)
        return b[Math.floor(n / 10)] + (n % 10 ? "-" + a[n % 10] : "");
      if (n < 1000)
        return (
          a[Math.floor(n / 100)] +
          " hundred" +
          (n % 100 ? " and " + numToWords(n % 100) : "")
        );
      if (n < 1000000)
        return (
          numToWords(Math.floor(n / 1000)) +
          " thousand" +
          (n % 1000 ? " " + numToWords(n % 1000) : "")
        );
      return "";
    };

    // Check if the input is a valid number
    if (isNaN(num) || num < 0) return "zero rupees only";

    // Split the number into whole and decimal parts
    const [whole, decimal] = num.toString().split(".");
    let result = "";

    // Convert the whole part
    if (whole) {
      result += numToWords(parseInt(whole)) + " rupees";
    } else {
      result += "zero rupees";
    }

    // Convert the decimal part if it exists
    if (decimal) {
      const paisa = parseInt(decimal.slice(0, 2)); // Get the first two digits for paise
      if (paisa) {
        result += " and " + numToWords(paisa) + " paise";
      }
    }

    return result + " only";
  };

  const handlePrint = () => {
    const printContents = componentRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const handleDownloadPdf = () => {
    const element = componentRef.current;
    const actions = actionRef.current;
    if (actions) actions.style.display = "none";

    const options = {
      margin: [10, 5, 10, 5], // top, left, bottom, right margins
      filename: `Voucher.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .set(options)
      .from(element)
      .save()
      .then(() => {
        if (actions) actions.style.display = "block";
      })
      .catch((err) => {
        console.error("Error generating PDF:", err);
        if (actions) actions.style.display = "block";
      });
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <div ref={componentRef}>
      <div className="voucher-actions" ref={actionRef}>
        <button onClick={handlePrint}>Print</button>
        <button onClick={handleDownloadPdf}>Download as PDF</button>
      </div>
      <div className="voucher-container">
        <header className="voucher-header">
          <h2>{data.company[0].company_name}</h2>
          <p>{data.itemlogo[0].add1}</p>
          <h3 style={{ marginTop: "6px" }}>Voucher (Head)</h3>
          <strong>
            <hr />
          </strong>
        </header>

        <section className="voucher-info">
          <div className="info-row">
            <span>
              <strong>Voucher No:</strong>
              {data.voucherDetails[0].voucher_code}
            </span>
            <span>
              <strong>Date:</strong>
              {new Date(
                data.voucherDetails[0].voucher_date
              ).toLocaleDateString()}
            </span>
          </div>
        </section>

        <table className="voucher-table">
          <thead>
            <tr>
              <th>Particulars</th>
              <th>Debit</th>
              <th>Credit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <p>{data.voucherDetails[0].account_name}</p>
                  </div>
                  <div>
                    <p>{data.voucherDetails[0].entry_type}</p>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    {" "}
                    <p>
                      <strong>To </strong>
                      {data.voucherDetails[1].account_name}
                    </p>
                  </div>
                  <div>
                    {" "}
                    <p>
                      <strong></strong>
                      {data.voucherDetails[1].entry_type}
                    </p>
                  </div>
                </div>

                <div>
                  <section className="amount-words">
                    <p>
                      <strong>Amount in words:</strong> Rs.
                      {numberToWords(data.voucherDetails[0].amount)}
                    </p>
                  </section>
                </div>
                {/* <p>On-Account of 00000095</p> */}
              </td>

              <td style={{ padding: "0" }}>
                <br />
                <div> {data.voucherDetails[0].amount}</div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                  style={{
                    width: "100%",
                    borderTop: "1px solid black",
                    padding: "2px",
                  }}
                >
                  {" "}
                  {data.voucherDetails[0].amount}
                </div>
              </td>
              <td style={{ padding: "0" }}>
                <br /> <br />
                <br />
                <div> {data.voucherDetails[0].amount}</div>
                <br />
                <br />
                <br />
                <br />
                <div
                  style={{
                    width: "100%",
                    borderTop: "1px solid black",
                    padding: "2px",
                  }}
                >
                  {" "}
                  {data.voucherDetails[1].amount}
                </div>
                <div></div>
              </td>
            </tr>
          </tbody>
        </table>

        <footer className="voucher-footer">
          <div className="footer-row">
            <span>Prepared By:</span>
            <span>Checked By:</span>
            <span>Authorized Signatory</span>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default JournalVoucher;
