import React from "react";
import { Table, InputNumber, Select, message } from "antd";
import Input from "@mui/material/Input";

import { IconButton, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
const { Option } = Select;

const DummyTable = ({
  data,
  onInputChange,
  onDeleteChange,
  onDiscountChange,
}) => {
  // Define columns
  const columns = [
    { key: "1", title: "Item", dataIndex: "item_name" },
    { key: "2", title: "Req Code", dataIndex: "req_code" },
    { key: "3", title: "Size", dataIndex: "size_name" },
    { key: "4", title: "Uom", dataIndex: "uom" },
    { key: "5", title: "Grade", dataIndex: "grade" },
    { key: "6", title: "Cost Center", dataIndex: "cost_center" },

    { key: "7", title: "Available Qty", dataIndex: "opening_value" },
    // { key: "7", title: "Qty", dataIndex: "qty" },
    {
      key: "8",
      title: "Qty",
      dataIndex: "qty",
    },
    {
      key: "8",
      title: "Issue Qty",
      dataIndex: "issue_qty",
      render: (text, record, index) => (
        <InputNumber
          min={0}
          max={parseInt(record.opening_value, 10)} // Convert opening_value to an integer
          value={record.issued_qty}
          onChange={(value) => {
            const openingValue = parseInt(record.opening_value, 10); // Ensure it's an integer
            if (value > openingValue) {
              message.warning(`Issued quantity cannot exceed ${openingValue}!`);
              return; // Prevent updating state if value exceeds max
            }
            onInputChange({ target: { value } }, index, record); // Update state
          }}
        />
      ),
    },
    { key: "9", title: "Pcs", dataIndex: "no_of_pcs" },
    {
      key: "10",
      title: "Action",
      render: (text, record, index) => (
        <IconButton
          onClick={() => onDeleteChange(record.uniq_id)}
          aria-label="delete"
          color="primary"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.uniq_id} // Assuming unique_code is unique for each row
    />
  );
};

export default DummyTable;
