import React, { useState, useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";

import "./PurchaseOrder.css";

const PurchaseOrder = (props) => {
  const [data, setData] = useState(props.pdfResponse);
  const componentRef = useRef();
  const actionRef = useRef();

  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const response = await fetch(`http://localhost:3001/purchase-order/${po_code}`);
  //             const data = await response.json();
  //             setData(data);
  //         } catch (err) {
  //             console.log('Failed to fetch purchase order data', err);
  //         }
  //     };
  //     fetchData();
  // }, [po_code]);

  const handlePrint = () => {
    const printContents = componentRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const handleDownloadPdf = () => {
    const element = componentRef.current;
    const actions = actionRef.current;
    if (actions) actions.style.display = "none";

    const options = {
      filename: `purchase.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .set(options)
      .from(element)
      .save()
      .then(() => {
        if (actions) actions.style.display = "block";
      })
      .catch((err) => {
        console.error("Error generating PDF:", err);
        if (actions) actions.style.display = "block";
      });
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  const totalAmount = data.details
    .reduce((total, item) => total + parseFloat(item.amount2), 0)
    .toFixed(2);
  const totalCharges = data.charges
    .reduce((total, charge) => total + parseFloat(charge.tax_val), 0)
    .toFixed(2);
  const netAmount = (
    parseFloat(totalAmount) + parseFloat(totalCharges)
  ).toFixed(2);

  return (
    <div ref={componentRef}>
      <div className="sale-actions" ref={actionRef}>
        <button onClick={handlePrint}>Print</button>
        <button onClick={handleDownloadPdf}>Download as PDF</button>
      </div>
      <div className="po-container">
        <div className="header">
          <div className="company-logo">
            <img src="" alt="" />
          </div>
          <div className="company-info">
            <h2>{data.company.company_name}</h2>
            <p>{data.add.add1}</p>
            <p>{data.add.ph1}</p>
            <p>Corp. Office: Ltd.</p>
          </div>
          <div className="po-info">
            <table>
              <tbody>
                <tr>
                  <td>State:</td>
                  <td>{data.company.state}</td>
                </tr>
                <tr>
                  <td>GST No.:</td>
                  <td>{data.add.gst_no}</td>
                </tr>
                <tr>
                  <td>PAN No.:</td>
                  <td>{data.add.pan_no}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h1 className="po-title" style={{ borderTop: "1px solid black" }}>
          Purchase Order
        </h1>
        <div
          style={{
            borderTop: "1px solid black",
            padding: "10px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ width: "60%" }}>
              <p>
                Vendor&nbsp;&nbsp;:<strong>&nbsp;M/S&nbsp;</strong>
                {data.header.party}
              </p>
              <p>{data.address.address}</p>
              <p>
                <strong>GSTIN No. : </strong>
                {data.add.gst_no}
              </p>
            </div>
            <div style={{ width: "20%" }}>
              <table style={{ height: "100%", width: "100%" }}>
                <tbody>
                  <tr>
                    <td>P.O. No:</td>
                    <td>{data.header.po_code}</td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td>
                      {new Date(data.header.po_date).toLocaleDateString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div style={{ borderTop: "1px solid black" }}>
          <p>
            Please supply the following material as per the Terms and Conditions
            mentioned below.
          </p>
        </div>

        <table className="main-table">
          <thead>
            <tr>
              <th style={{ textAlign: "right" }}>S.N.</th>
              <th>Item</th>
              <th style={{ textAlign: "center" }}>HSN</th>
              <th>UOM</th>
              <th style={{ textAlign: "right" }}>Qty</th>
              <th style={{ textAlign: "right" }}>Rate</th>
              <th style={{ textAlign: "right" }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.details.map((item, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "right",
                  }}
                >
                  {index + 1}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.item}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {item.hsn}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.uom}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "right",
                  }}
                >
                  {item.total_qty}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "right",
                  }}
                >
                  {item.rate1}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "right",
                  }}
                >
                  {item.amount2}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="5" className="total-text">
                TOTAL Amount
              </td>
              <td colSpan="2" style={{ textAlign: "right" }}>
                {totalAmount}
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <table
            style={{
              width: "40%",
              textAlign: "right",
              marginTop: "1rem",
              align: "right",
            }}
          >
            <tfoot>
              <tr>
                <td colSpan="2" className="total-text">
                  TOTAL Amount Before Tax
                </td>
                <td colSpan="2" style={{ textAlign: "right" }}>
                  {" "}
                  {totalAmount}
                </td>
              </tr>
              {data.charges &&
                data.charges.map((charge, index) => (
                  <tr key={index}>
                    <td colSpan="2" className="border">
                      <div>
                        {" "}
                        {charge.charge_name} @ {Math.floor(charge.charge_value)}{" "}
                        {charge.charge_type === "p" ? "%" : charge.charge_type}
                      </div>
                    </td>
                    <td
                      colSpan="2"
                      className="right border"
                      style={{ textAlign: "right" }}
                    >
                      {charge.tax_val}
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan="2" className="total-text">
                  Final Net Amount
                </td>
                <td colSpan="2" style={{ textAlign: "right" }}>
                  {" "}
                  <strong>{netAmount}</strong>{" "}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid black",
          }}
        >
          <div style={{ width: "50%" }}>
            <p>Important:</p>
            <ul>
              <li>
                Please quote this P.O. No. in your challan, invoice(s), supplies
                & correspondence.
              </li>
              <li>Please mention your GST No. & TIN on your letterhead.</li>
              <li>
                The material will be accepted on Challan/Packing sheet your
                original Invoice(s) Copy along with.
              </li>
              <li>The material should be tested & certified prior receipt.</li>
              <li>All disputes subject to Delhi Jurisdiction.</li>
            </ul>
          </div>
          <div
            className="sign-line"
            style={{ width: "50%", textAlign: "right" }}
          >
            <p>For: Sagarmatha Steels Industries Pvt. Ltd.</p>
            <div style={{ height: "50px" }}></div>

            <p>Authorized Signatory</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrder;
