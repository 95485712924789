import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  message,
  Button,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import classes from "./Page.module.css";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Ledger = ({ columns, account, data }) => {
  const [filteredData, setFilteredData] = useState(data); // Table data
  const [readOnlyValue, setReadOnlyValue] = useState(""); // Opening balance
  const [selectedAccount, setSelectedAccount] = useState(null); // Selected account
  const [loading, setLoading] = useState(false); // Loading state
  const [dateRange, setDateRange] = useState([null, null]); // Start and End Dates

  useEffect(() => {
    // Calculate dynamic fiscal year dates
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    console.log(currentYear);
    console.log(currentMonth);
    // Assuming fiscal year starts in April
    const fiscalYearStart =
      currentMonth < 3 // Before April
        ? `${currentYear - 1}-04-01`
        : `${currentYear}-04-01`;
    console.log(fiscalYearStart);

    const fiscalYearEnd =
      currentMonth < 3 // Before April
        ? `${currentYear}-03-31`
        : `${currentYear + 1}-03-31`;
    console.log(fiscalYearEnd);
    setDateRange([
      dayjs(fiscalYearStart, "YYYY-MM-DD"),
      dayjs(fiscalYearEnd, "YYYY-MM-DD"),
    ]);
  }, []);

  const fetchLedgerData = async (startDate, endDate) => {
    if (!startDate || !endDate) return;

    setFilteredData([]);
    setReadOnlyValue("");
    setLoading(true);

    try {
      const response = await axios.get(
        "http://sastetab.com/api/v1/controldata/additional-data-of-trail-opening-withdate",
        {
          params: {
            start_date: startDate,
            end_date: endDate,
          },
          withCredentials: true,
        }
      );

      console.log(response, "This is response");
      setFilteredData(response.data.data.site_list.rows);
    } catch (error) {
      message.error("Failed to fetch ledger data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAccountChange = (value) => {
    setSelectedAccount(value);

    // Filter data by account_code
    const filtered = data.filter((item) => item.account_code === value);
    setFilteredData(filtered);
  };

  const handleDateFilter = (dates) => {
    setDateRange(dates);

    if (selectedAccount && dates && dates[1]) {
      const [startDate, endDate] = dates;
      fetchLedgerData(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
    }
  };

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <div style={{}}>
            <label
              htmlFor="account-select"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "bold",
              }}
            >
              Account
            </label>
            <Select
              id="account-select"
              placeholder="Select Account"
              onChange={handleAccountChange}
              style={{ width: "100%" }}
              value={selectedAccount}
            >
              {account.rows.map((row) => (
                <Option value={row.account_code} key={row.account_code}>
                  {row.account} {/* Displaying account name as label */}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div style={{}}>
            <label
              htmlFor="account-select"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "bold",
              }}
            >
              Date
            </label>
            <RangePicker
              onChange={handleDateFilter}
              allowClear={true}
              value={dateRange}
            />
          </div>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="voucher_code"
        loading={loading}
        pagination={{ pageSize: 10 }}
        // className={classes["tableStriped"]}
      />
    </div>
  );
};

export default Ledger;
