import React, { useRef, useState } from "react";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import axios from "axios"; // Import axios
import classes from "../Pages.module.css";
import DataField from "./DataField";
import ReqCodeModal from "./ReqcodeModel"; // Import the modal component
import CustomerModal from "./CustomerItem";

const BasicDetail = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalForApiVisible, setIsModalForApiVisible] = useState(false);
  const [codalData, setCodalData] = useState([]);
  const planDateRef = useRef(null);
  const requirementDateRef = useRef(null);
  const currencyrate = useRef(null);
  const type = useRef(null);
  const currency = useRef(null);
  const ordertype = useRef(null);
  const partyref = useRef(null);
  const poRef = useRef(null);
  const reqtype = useRef(null);

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["poHdr"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        poHdr: newdata,
      };
    });
  };

  const handleSChange = async (val, param) => {
    if (param === "req_code") {
      // Nullify purreqDetail when req_code changes
      props.setData((data) => {
        const newdata = [...data.poHdr];
        newdata[0][param] = val;

        return {
          ...data,
          poHdr: newdata,
          purreqDetail: null, // Set purreqDetail to null
        };
      });
      try {
        props.onReqCodeChange(val);
        // API call using axios
        const response = await axios.get(
          `http://sastetab.com/api/v1/purchaseorder/purchase_order_with_requisition/${val}`,
          { withCredentials: true }
        );

        console.log("API Response:", response.data.data.customer.rows);

        // Update the state with the API response
        props.setData((data) => {
          const newdata = [...data["poHdr"]];
          newdata[0][param] = val;

          return {
            ...data,
            poHdr: newdata,
            purreqDetail: response.data.data.customer.rows, // Store the API response here
          };
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed (e.g., set an error state or show a message)
      }
    } else if (param === "type") {
      // Handle type change
      props.setData((data) => {
        const newdata = [...data["poHdr"]];
        newdata[0][param] = val;

        // If the new type is "w", set req_code to null
        if (val === "w" && data.poHdr[0].req_code) {
          newdata[0]["req_code"] = null;
        }

        return {
          ...data,
          poHdr: newdata,
          purreqDetail: [
            {
              item_code: null,
              uom_code: null,
              hsn: null,
              size_code: null,
              quality_code: null,
              total_qty: null,
              remarks: null,
              rate: null,
              amount: null,
              rate1: null,
              amount2: null,
              discount_on: null,
              discount: null,
              dis_type: null,
              remarks: null,
            },
          ],
        };
      });
    } else {
      // If param is not "req_code", just update the state without API call
      props.setData((data) => {
        const newdata = [...data["poHdr"]];
        newdata[0][param] = val;

        return {
          ...data,
          poHdr: newdata,
        };
      });
    }
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["poHdr"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        poHdr: newdata,
      };
    });
  };

  const handleReqCodeClick = () => {
    setIsModalVisible(true); // Show the modal when Req Code is clicked
  };

  const handleModalRowClick = (record) => {
    // console.log("Selected Row:", record);
    // You can update the state with the selected value
    props.setData((data) => {
      const newdata = [...data["poHdr"]];
      newdata[0]["req_code"] = record.code;
      return {
        ...data,
        poHdr: newdata,
      };
    });
  };

  const handleButtonClick = async () => {
    try {
      console.log(props.data);
      // Destructure the required values from props
      const { req_code } = props.data;

      // Make the API call, passing dealercode and customer_code as query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/purchaseorder/additional-data-of-customer-other-sauda?reqCode=${req_code}`,
        { withCredentials: true }
      );

      // Log the API response for debugging
      console.log("API Response:", response.data.data.Requisition.rows);

      // Update the modal data state with the API response
      setCodalData(response.data.data.Requisition.rows);

      // Show the modal
      setIsModalForApiVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleApiModalRowClick = async (record) => {
    console.log(record);
    props.setData((data) => {
      return {
        ...data,

        purreqDetail: [...data.purreqDetail, record],
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            name="Requisition Code"
            param="REQ_CODE"
            value={props.data.req_code}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          options={props.ad.TYPE}
          handleChange={handleSChange}
          type="Select"
          id="type"
          required="True"
          name="Po Type"
          param="type"
          value={props.data.type}
          rules={[{ message: "missing name" }]}
          disabled={props.editordMode1}
          ref={type}
          onNextFocus={() => planDateRef.current.focus()}
        />

        {props.data.type !== "w" && (
          <DataField
            editMode={props.editMode}
            lg={4}
            md={24}
            id="type"
            options={props.ad.REQ_CODE}
            handleChange={handleSChange}
            type="Select"
            required="True"
            name="Indent Code"
            param="req_code"
            //  onClick={handleReqCodeClick} // Add onClick handler here as well
            value={props.data.req_code}
            rules={[{ message: "missing name" }]}
            ref={reqtype}
            onNextFocus={() => planDateRef.current.focus()}
            disabled={props.editordMode1}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          type="Date"
          id="deal_date"
          required="True"
          name="Deal Date"
          param="deal_date"
          value={props.data.deal_date}
          rules={[{ message: "missing name" }]}
          ref={planDateRef}
          onNextFocus={() => requirementDateRef.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          options={props.ad.DEAL_TYPE}
          handleChange={handleSChange}
          type="Select"
          id="deal_type_cd"
          required="True"
          name="Deal Type"
          param="deal_type_cd"
          value={props.data.deal_type_cd}
          rules={[{ message: "missing name" }]}
          ref={requirementDateRef}
          onNextFocus={() => currency.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleSChange}
          options={props.ad.CURRENCY_CODE}
          type="Select"
          name="Currency"
          id="currency_cd"
          required="True"
          param="currency_cd"
          value={props.data.currency_cd}
          ref={currency}
          onNextFocus={() => currencyrate.current.focus()}
        />
        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleChange}
          name="Currency Rate"
          required="True"
          id="currency_rate1"
          param="currency_rate1"
          value={props.data.currency_rate1}
          ref={currencyrate}
          onNextFocus={() => partyref.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.PARTY_CODE}
          type="Select"
          name="Vendor"
          required="True"
          id="party_code"
          param="party_code"
          value={props.data.party_code}
          ref={partyref}
          onNextFocus={() => poRef.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="PO Ref"
          required="True"
          id="po_ref"
          param="po_ref"
          value={props.data.po_ref}
          ref={poRef}
          onNextFocus={() => ordertype.current.focus()}
        />

        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleSChange}
          options={props.ad.ORDER_TYPE}
          type="Select"
          name="Order Type"
          id="po_type1"
          required="True"
          param="po_type1"
          value={props.data.po_type1}
          ref={ordertype}
          // onNextFocus={() => requestedByRefrate.current.focus()}
        />
        <Col span={5} style={{ marginRight: "16px" }}>
          {/* Adjusted span for better width distribution */}
          <div
            style={{
              marginBottom: "8px",
              marginTop: "2px",
              // border: "2px solid red",
            }}
          >
            {/* Container for margin */}
            <label
              style={{
                display: "block",
                marginBottom: "15px",
                fontSize: "10px", // Smaller font size
                fontWeight: "bold", // Bold text
                color: "#333", // Darker color, you can choose any color
              }}
            >
              NET QTY.
            </label>
            <input
              type="text"
              value={props.netQty}
              readOnly
              style={{
                width: "90%", // Ensure input takes full width of column
                padding: "5px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                textAlign: "right",
                boxSizing: "border-box", // Include padding and border in element's total width and height
              }}
            />
          </div>
        </Col>
        <Col span={5}>
          {/* Adjusted span for better width distribution */}
          <div style={{ marginBottom: "8px", marginTop: "2px" }}>
            {/* Container for margin */}
            <label
              style={{
                display: "block",
                marginBottom: "15px",
                fontSize: "10px", // Smaller font size
                fontWeight: "bold", // Bold text
                color: "#333",
              }}
            >
              NET AMOUNT
            </label>
            <input
              type="text"
              value={props.netAmount}
              readOnly
              style={{
                width: "90%", // Ensure input takes full width of column
                padding: "5px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                textAlign: "right",
                backgroundColor: "#ffffff",
                boxSizing: "border-box", // Include padding and border in element's total width and height
              }}
            />
          </div>
        </Col>
        {props.data.type !== "w" && (
          <Button
            type="primary"
            onClick={handleButtonClick}
            style={{ marginLeft: "15px", marginTop: "30px" }}
          >
            Add item
          </Button>
        )}
      </Row>
      <CustomerModal
        title="API Data Modal"
        visible={isModalForApiVisible}
        onRowClick={handleApiModalRowClick}
        onClose={() => setIsModalForApiVisible(false)}
        // onCancel={handleApiModalClose}
        data1={codalData}
      />

      {/* <ReqCodeModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onRowClick={handleModalRowClick}
      /> */}
    </div>
  );
};

export default BasicDetail;
