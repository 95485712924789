import React from "react";
import { Table, InputNumber, Select, notification } from "antd";
const { Option } = Select;

const DummyTable = ({ data, onInputChange, calculateAmount2 }) => {
  // Define columns

  const columns = [
    { title: "Item Name", dataIndex: "item", key: "item" },
    { title: "Hsn", dataIndex: "hsn", key: "hsn" },
    { title: "Uom", dataIndex: "uom", key: "uom" },
    { title: "Size Name", dataIndex: "size", key: "size" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    // { title: "Quality Code", dataIndex: "quality_code", key: "quality_code" },
    {
      title: "Quantity",
      key: "total_qty",
      dataIndex: "total_qty",
      // render: (text, record) => (
      //   <InputNumber
      //     value={text}
      //     onChange={(value) => {
      //       if (value <= record.initial_qty) {
      //         onInputChange(value, record.unique_code, "total_qty");
      //       }
      //       else {
      //         showNotification(); // Show notification if the value is greater than initial
      //       }
      //     }}
      //   />
      // ),
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={(value) => {
            // Ensure the new value is not greater than the initial quantity
            if (value <= record.initial_qty) {
              onInputChange(value, record.gate_uniq, "total_qty");
            } else {
              // Optionally show a warning message if the value exceeds the initial quantity
              alert(`Quantity cannot exceed ${record.initial_qty}`);
            }
          }}
        />
      ),
    },
    // { title: "Uniq Id", dataIndex: "unique_code", key: "unique_code" },
    {
      title: "Rate",
      key: "rate",
      dataIndex: "rate",
    },

    {
      title: "Dis On",
      key: "discount_on",
      render: (text, record) =>
        record.discount_on ? (
          <span>{record.discount_on === "r" ? "Rate" : "Total Amt"}</span>
        ) : null, // Show nothing if discount_on is null or undefined
    },

    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      // render: (text, record) => (
      //   <span>{record.amount ? record.amount.toFixed(2) : null}</span>
      // ),
    },

    {
      title: "Dis Type",
      key: "dis_type",
      render: (text, record) =>
        record.dis_type ? (
          <span>{record.dis_type === "p" ? "%" : "Amount"}</span>
        ) : null, // Show nothing if dis_type is null or undefined
    },

    {
      title: "Final Rate",
      dataIndex: "rate1",
      key: "rate1",
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Net Amount",
      key: "amount2",
      dataIndex: "amount2",
      // render: (text, record) => (
      //   <span>{record.amount2 ? record.amount2.toFixed(2) : null}</span>
      // ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.gate_uniq} // Assuming unique_code is unique for each row
    />
  );
};

export default DummyTable;
