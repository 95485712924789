import { Row, Col, Card } from "antd";
import classes from "./Dashboard.module.css";
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
const DataCards = (props) => {
  // // console.log(props.employeeData.analysis);

  // // console.log(employeeData.analysis);
  // console.log(employeeData.analysis);
  // console.log(employeeData.analysis);
  return (
    <Row>
      <Col lg={8}>
        <Card
          style={{
            backgroundColor: "#daebf336", // Light sky blue with 0.9 opacity
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Link
                to="/production/transaction/daily-prod-new?rolling"
                style={{ textDecoration: "none" }}
              >
                <PlusCircleOutlined
                  style={{
                    fontSize: "24px",
                    color: "#45b6fe",
                    marginRight: "8px",
                  }}
                />
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Add Rolling
                </span>
              </Link>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col
                lg={18}
                md={24}
                className={classes["RataCol"]}
                style={{
                  display: "flex", // Make the container a flexbox
                  justifyContent: "space-between", // Add space between heading and data
                  alignItems: "center", // Align items vertically in the center
                  fontWeight: "900", // Make text bold
                }}
              >
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "17px",
                    fontWeight: 600,
                  }}
                >
                  Rolling Production Monthly
                </span>{" "}
                {/* Heading */}
              </Col>

              <Col lg={6}>
                <div
                  className={classes["Rata1"]}
                  style={{
                    // border: "2px solid red", // Keep the existing border style
                    padding: "2px", // Add some padding for the data box
                  }}
                >
                  {props.data.TotalSaudaMonthlyValue !== null
                    ? props.data.TotalSaudaMonthlyValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>

              <Col
                lg={18}
                className={classes["RataCol"]}
                style={{
                  display: "flex", // Flexbox layout to make content inline
                  justifyContent: "space-between", // Space between heading and data
                  alignItems: "center", // Vertically center the content
                  fontWeight: "900", // Bold text for the heading
                }}
              >
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "17px",
                    fontWeight: 600,
                  }}
                >
                  Rolling Production Yearly
                </span>{" "}
                {/* Heading */}
              </Col>

              <Col lg={6}>
                <div
                  className={classes["Rata1"]}
                  style={{
                    // color: "white", // Keep existing white color for the text
                    padding: "2px", // Add some padding for better appearance
                  }}
                >
                  {props.data.TotalSaudaYearlyValue !== null
                    ? props.data.TotalSaudaYearlyValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>
          </Row>
        </Card>
      </Col>
      <Col
        lg={{ span: 8, offset: 0 }}
        md={11}
        xs={24}
        className={classes["Col"]}
      >
        <Card
          style={{
            background: "#daebf336",
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Link
                to="/production/transaction/furnance-create?furnance"
                style={{ textDecoration: "none" }}
              >
                <PlusCircleOutlined
                  style={{
                    fontSize: "24px",
                    color: "#45b6fe",
                    marginRight: "8px",
                  }}
                />
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Add Furnance
                </span>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Make the container a flexbox
                justifyContent: "space-between", // Add space between heading and data
                alignItems: "center", // Align items vertically in the center
                fontWeight: "900", // Make text bold
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Furnace Production Monthly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // border: "2px solid red", // Keep the existing border style
                  padding: "2px", // Add some padding for the data box
                }}
              >
                {props.data.TotalMonthlyOrderValue !== null
                  ? props.data.TotalMonthlyOrderValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>

            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Flexbox layout to make content inline
                justifyContent: "space-between", // Space between heading and data
                alignItems: "center", // Vertically center the content
                fontWeight: "900", // Bold text for the heading
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Furnace Production Yearly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalYearlyOrderValue !== null
                  ? props.data.TotalYearlyOrderValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col
        lg={{ span: 8, offset: 0 }}
        md={11}
        xs={24}
        className={classes["Col"]}
      >
        <Card
          style={{
            backgroundColor: "#daebf336", // Very light gray with 0.9 opacity
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Link
                to="/production/transaction/breakdown-create?breakdown"
                style={{ textDecoration: "none" }}
              >
                <PlusCircleOutlined
                  style={{
                    fontSize: "24px",
                    color: "#45b6fe",
                    marginRight: "8px",
                  }}
                />
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Add Breakdown
                </span>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Make the container a flexbox
                justifyContent: "space-between", // Add space between heading and data
                alignItems: "center", // Align items vertically in the center
                fontWeight: "900", // Make text bold
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Breakdown Feeding Monthly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // border: "2px solid red", // Keep the existing border style
                  padding: "2px", // Add some padding for the data box
                }}
              >
                {props.data.TotalInvoiceMonthlyValue !== null
                  ? props.data.TotalInvoiceMonthlyValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>

            <Col
              md={24}
              className={classes["RataCol"]}
              style={{
                display: "flex", // Flexbox layout to make content inline
                justifyContent: "space-between", // Space between heading and data
                alignItems: "center", // Vertically center the content
                fontWeight: "900", // Bold text for the heading
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "17px", fontWeight: 600 }}
              >
                Breakdown Feeding Yearly
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalInvoiceYearlyValue !== null
                  ? props.data.TotalInvoiceYearlyValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default DataCards;
