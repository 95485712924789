import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetail from "./BasicDetail";
import SizeDetail from "./SizeDetail";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const { TabPane } = Tabs;

const RequisitionView = (props) => {
  // console.log(props);
  const { id } = useParams();
  const employeeData = useContext(DataContext);

  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [editDode, setEditDode] = useState(true);
  const [editMode1, setEditMode1] = useState(true);
  const [allData, setAllData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [ad, setAD] = useState(null);
  const [itemm, setItemm] = useState(null);
  const [responseDataArray, setResponseDataArray] = useState([]);
  const [dataFetchingCompleted, setDataFetchingCompleted] = useState();
  const [customers, setCustomers] = useState([]);
  const [addres, setAddres] = useState(null);

  useEffect(() => {
    setAllData(null);
    setStoreData(null);
    setAD(null);

    axios
      .get(employeeData.URL + "/api/v1/salesOrder/" + id, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setItemm(response.data.data);
        setDataFetchingCompleted(response.data.data.bookingSize.length);
        const distributorCode = response.data.data.salesOrder[0].dealer_name;
        // console.log(distributorCode, "dddddddddddddddddddddddddddd");
        const dealer_code = response.data.data.salesOrder[0].distributor_code;
        // Make a second API call using distributorCode as a parameter
        axios
          .get(
            employeeData.URL +
              "/api/v1/salesInvoice/additional-data-of-customer/" +
              distributorCode,
            {
              withCredentials: true,
            }
          )
          .then((secondResponse) => {
            // console.log(secondResponse);
            const customer = secondResponse.data.data.customer.rows; // Replace with the actual response structure
            setCustomers(customer);

            axios
              .get(
                employeeData.URL +
                  "/api/v1/salesInvoice/additional-data-of-cust/" +
                  dealer_code, // Use dealer_code in place of val
                {
                  withCredentials: true,
                }
              )
              .then((thirdResponse) => {
                // console.log(thirdResponse);
                const add = thirdResponse.data.data.custdetail.rows; // Replace with the actual response structure
                setAddres(add); // Update state with the new address or details
              });

            setAllData((allData) => {
              let newDetails = response.data.data;

              // Modify reqDetail array to include Mode property with value true for each object
              newDetails.bookingSize.forEach((detail) => {
                detail.Mode = true;
              });

              return {
                ...newDetails,
              };
            });

            setStoreData((storeData) => {
              let newDetails = response.data.data;

              return {
                ...newDetails,
              };
            });
          });
      })

      .catch((error) => {
        // console.log(error);
      });

    axios
      .get(employeeData.URL + "/api/v1/salesOrder/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            TYPE: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "wr", value: "With Contract" },
                { key: "w", value: "Without Contract" },
              ],
            },
            Dis_On: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "r", value: "Rate" },
                { key: "ta", value: "Total Amt" },
              ],
            },
            Dis_Type: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "p", value: "%" },
                { key: "a", value: "A" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async (item, index) => {
      try {
        // console.log(item.item_code);
        const response = await axios.get(
          `${employeeData.URL}/api/v1/salesOrder/additional-data-of-hsn/${item.item_code}`,
          {
            withCredentials: true,
          }
        );
        // console.log(response.data.data);
        const data = response.data.data;
        setResponseDataArray((prevDataArray) => {
          const newArray = [...prevDataArray];
          newArray[index] = data; // Set the response data at the correct index
          return newArray;
        });
      } catch (error) {
        console.error(
          `Error fetching data for item_code ${item.item_code}:`,
          error
        );
      }
    };

    const fetchAllData = async () => {
      if (!itemm || !itemm.bookingSize) {
        console.error("itemm or itemm.bookingSize is null or undefined", itemm);
        return;
      }

      try {
        await Promise.all(
          itemm.bookingSize.map((item, index) => fetchData(item, index))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllData().then(() => {
      // console.log(responseDataArray.length);
      // console.log(responseDataArray.length);
    });
  }, [itemm, setResponseDataArray]);
  // const isValidObject = (obj) => {
  //   return obj.size && obj.grade && obj.uom && obj.cost;
  // };

  // // Determine if all objects in responseDataArray meet the condition
  // const allObjectsValid = responseDataArray.every(isValidObject);

  const handleEditButtonClick = () => {
    setEditMode(true);
    setEditDode(true);
  };

  const handleSave = (event) => {
    // console.log(storeData, "this is store data");
    // console.log(storeData, "this is store data");
    const postData = {
      ...allData,
    };

    const postDataS = {
      ...storeData,
      bookingSize: allData.bookingSize.map((dep) => {
        return {
          ...dep,
        };
      }),
    };

    // console.log(postData);
    // console.log(postData);
    // console.log(postData);

    // Filter out undefined elements from reqDetail array
    postData.bookingSize = postData.bookingSize.filter(
      (detail) => detail !== null && detail !== undefined
    );

    // Process the filtered array
    postData.bookingSize.forEach((lang, index) => {
      if (!lang.unique_id) {
        postData.bookingSize[index] = {
          ...lang,
          PARAM: "INSERT",
        };
      } else {
        postData.bookingSize[index] = {
          ...lang,
          PARAM: "UPDATE",
        };
      }
    });

    storeData.bookingSize.forEach((lang, index) => {
      const dataIndex = postData.bookingSize.findIndex(
        (element) => element.unique_id === lang.unique_id
      );
      if (dataIndex === -1) {
        postData.bookingSize.push({
          unique_id: lang.unique_id,
          PARAM: "DELETE",
        });
      }
    });

    // console.log(JSON.stringify(postData, undefined, 2));

    // if (
    //   postData.purreqHdr[0].plan_date == null ||
    //   postData.purreqHdr[0].requirement_date == null ||
    //   postData.purreqHdr[0].dept_code == null ||
    //   postData.purreqHdr[0].requester_code == null
    // ) {
    //   message.error({
    //     content: "Please Fill The Mandatory Fields!!!",
    //     className: "custom-class",
    //     style: {
    //       marginTop: "1vh",
    //     },
    //   });
    //   setLoading(false);
    //   return; // Stop further execution if validation fails
    // }

    // const allFieldsNull = postData.purreqDetail.every(
    //   (detail) =>
    //     detail.item_code === null &&
    //     detail.uom_code === null &&
    //     detail.size_code === null &&
    //     detail.quality_code === null &&
    //     detail.purpose === null &&
    //     detail.actual_bal === null &&
    //     detail.qty === null
    // );

    // if (allFieldsNull) {
    //   message.error({
    //     content: "Please Fill At Least One Row In The Item Detail Tab!!!",
    //     className: "custom-class",
    //     style: {
    //       marginTop: "1vh",
    //     },
    //   });
    //   setLoading(false);
    //   return; // Stop further execution if all fields are null
    // }

    // const fieldDisplayNameMap = {
    //   item_code: "Item",
    //   size_code: "Size",
    //   quality_code: "Grade",

    //   qty: "Quantity",
    //   uom_code: "Uom",
    // };

    // const invalidRows = postData.bookingSize.filter((detail, index) => {
    //   if (detail.PARAM === "DELETE") {
    //     return false;
    //   }
    //   const mandatoryFields = [
    //     "item_code",
    //     "size_code",
    //     "quality_code",

    //     "qty",
    //     "uom_code",
    //   ];
    //   const missingFields = mandatoryFields.filter(
    //     (field) =>
    //       detail[field] === null ||
    //       detail[field] === undefined ||
    //       detail[field] === ""
    //   );
    //   if (missingFields.length > 0) {
    //     // Map field names to display names
    //     const displayNames = missingFields.map(
    //       (field) => fieldDisplayNameMap[field]
    //     );
    //     // Construct error message for this row
    //     const errorMessage = `Row ${
    //       index + 1
    //     }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
    //     // Display error message
    //     message.error({
    //       content: errorMessage,
    //       className: "custom-class",
    //       style: {
    //         marginTop: "1vh",
    //       },
    //     });

    //     setLoading(false);
    //     return true;
    //   }
    //   return false;
    // });
    // if (invalidRows.length > 0) {
    //   return; // Stop further execution if validation fails
    // }
    const missingFields = [];

    // Check for missing or empty fields in allData.salesOrder
    if (
      allData.salesOrder[0].booking_date == null ||
      allData.salesOrder[0].booking_date === ""
    ) {
      missingFields.push("Booking Date");
    }
    if (
      allData.salesOrder[0].dealer_name == null ||
      allData.salesOrder[0].dealer_name === ""
    ) {
      missingFields.push("Dealer Name");
    }
    if (
      allData.salesOrder[0].distributor_code == null ||
      allData.salesOrder[0].distributor_code === ""
    ) {
      missingFields.push("Customer name");
    }
    if (
      allData.salesOrder[0].del_site_code == null ||
      allData.salesOrder[0].del_site_code === ""
    ) {
      missingFields.push("Delivery Site ");
    }
    if (
      allData.salesOrder[0].freight_type_code == null ||
      allData.salesOrder[0].freight_type_code === ""
    ) {
      missingFields.push("Freight Type ");
    }
    if (
      allData.salesOrder[0].customer_po_no == null ||
      allData.salesOrder[0].customer_po_no === ""
    ) {
      missingFields.push("Customer PO No");
    }
    if (
      allData.salesOrder[0].type == null ||
      allData.salesOrder[0].type === ""
    ) {
      missingFields.push("Type");
    }

    // If there are any missing fields, show an error message and stop further execution
    if (missingFields.length > 0) {
      message.error({
        content: `Please Fill The Mandatory Fields: ${missingFields.join(
          ", "
        )}`,
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return; // Stop further execution if validation fails
    }

    const allFieldsNull = allData.bookingSize.every(
      (detail) =>
        detail.item_code === null &&
        detail.uom_code === null &&
        detail.size_code === null &&
        detail.quality_code === null &&
        detail.total_qty === null &&
        detail.hsn === null &&
        detail.remarks === null &&
        detail.rate === null &&
        detail.amount === null &&
        detail.rate1 === null &&
        detail.amount2 === null &&
        detail.discount_on === null &&
        detail.discount === null &&
        detail.dis_type === null &&
        detail.qty === null
    );

    if (allFieldsNull) {
      message.error({
        content: "Please Fill At Least One Row In The Item Detail Tab!!!",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return; // Stop further execution if all fields are null
    }

    // //////////////////////with level
    const fieldDisplayNameMap = {
      item_code: "Item",
      uom_code: "Uom",
      size_code: "Size",
      quality_code: "Grade",
      total_qty: "Quantity",
      hsn: "HSN",

      remarks: "Remarks",
      rate: "Basic Rate",
      amount: "Amount",
      rate1: "Rate1",
      amount2: "Gross Amount",
      discount_on: "Discount On",
      discount: "Discount Value",
      dis_type: "Discount Type",
    };

    const invalidRows = allData.bookingSize.filter((detail, index) => {
      const mandatoryFields = [
        "item_code",
        "uom_code",
        "size_code",
        "quality_code",

        "hsn",
        "total_qty",

        "rate",
        "amount",
        "rate1",
        "amount2",
      ];
      const missingFields = mandatoryFields.filter(
        (field) =>
          detail[field] === null ||
          detail[field] === undefined ||
          detail[field] === ""
      );
      if (missingFields.length > 0) {
        // Map field names to display names
        const displayNames = missingFields.map(
          (field) => fieldDisplayNameMap[field]
        );
        // Construct error message for this row
        const errorMessage = `Row ${
          index + 1
        }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
        // Display error message
        message.error({
          content: errorMessage,
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });

        setLoading(false);
        return true;
      }
      return false;
    });

    if (invalidRows.length > 0) {
      return; // Stop further execution if validation fails
    }

    // Move this line outside of the filt

    axios
      .patch(employeeData.URL + "/api/v1/salesOrder/" + id, postData, {
        withCredentials: true,
        credentials: "include",
      })
      .then((response) => {
        message.success({
          content: "Sales Order Updated Successfully!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
        history("/sales/transaction/salesOrder-detail");
      })
      .catch((err) => {
        message.error({
          content: "An Error Occurred!!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
      });

    // setEditMode(false);
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/sales/transaction/salesOrder-detail");
  };

  return (
    <>
      {allData && ad && responseDataArray.length == dataFetchingCompleted ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={handleEditButtonClick}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Col
                style={{
                  // border: "2px solid red",
                  marginTop: "5px",
                  marginBottom: "-28px",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#1677ff" }}>
                  Sales Order
                </span>

                <BasicDetail
                  create={true}
                  ad={ad}
                  setData={setAllData}
                  data={allData.salesOrder[0]}
                  editMode={editMode}
                  customers={customers}
                  addres={addres}
                  editMode1={editMode1}
                />
              </Col>
              <Tabs
                defaultActiveKey="0"
                centered // Center the Tabs
                type="line" // Set the type to "card"
                tabBarGutter="10"
                id="tabs"
                tabBarUnderlineStyle={{ borderWidth: 0 }} // Remove border from the tab bar
              >
                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Item Details
                    </span>
                  }
                  key="0"
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px",
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    {dataFetchingCompleted && (
                      <SizeDetail
                        ad={ad}
                        setData={setAllData}
                        data={allData.bookingSize}
                        editMode={editMode}
                        editDode={editDode}
                        setEditDode={setEditDode}
                        responseDataArray={responseDataArray}
                        lenght={dataFetchingCompleted}
                        setResponseDataArray={setResponseDataArray}
                        setDataFetchingCompleted={setDataFetchingCompleted}
                      />
                    )}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default RequisitionView;
