import React, { useState, useEffect, useRef } from 'react';
import html2pdf from 'html2pdf.js';
import './Receipt.css'; // Import your CSS file

const Receipt = (props) => {
  const [data, setData] = useState(props.pdfResponse);
  const componentRef = useRef();
  const actionRef = useRef();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`http://localhost:3001/mrir/${mrir_code}`);
  //       const data = await response.json();
  //       setData(data);
  //     } catch (err) {
  //       console.log('Failed to fetch factory arrival data', err);
  //     }
  //   };
  //   fetchData();
  // }, [mrir_code]);

  const handlePrint = () => {
    const printContents = componentRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const handleDownloadPdf = () => {
    const element = componentRef.current;
    const actions = actionRef.current;
    if (actions) actions.style.display = 'none';

    const options = {
      filename: `Receipt.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    html2pdf()
      .set(options)
      .from(element)
      .save()
      .then(() => {
        if (actions) actions.style.display = 'block';
      })
      .catch((err) => {
        console.error('Error generating PDF:', err);
        if (actions) actions.style.display = 'block';
      });
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  const totalAmount = data.items.reduce((total, item) => total + parseFloat(item.amount2), 0).toFixed(2);
  const totalCharges = data.charges.reduce((total, charge) => total + parseFloat(charge.tax_val), 0).toFixed(2);
  const netAmount = (parseFloat(totalAmount) + parseFloat(totalCharges)).toFixed(2);
  return (
    <div className="container" ref={componentRef}>
      <div className="sale-actions" ref={actionRef}>
        <button onClick={handlePrint}>Print</button>
        <button onClick={handleDownloadPdf}>Download as PDF</button>
      </div>
      <div className="receipt-note">
        <header>
          <h1>{data.company.company_name}</h1>
          <p>{data.address.add1}</p>
          <h2>STORE MATERIAL RECEIPT NOTE</h2>
        </header>

        <div className="receipt-details">
          <div className="left-column">
            <p><strong>Vendor Name:</strong> {data.header.vendor}</p>
            <p><strong>Vendor Address:</strong>{data.header.vendor_address}</p>

            <p><strong>PO No:</strong> {data.header.po_ref}</p>
            <p><strong>PO Date:</strong>{data.header.po_date}</p>
          </div>
          <div className="right-column">
            <p><strong>Gate Ent no.:</strong> {data.header.gate_pass}</p>
            <p><strong>Mrir Ref No. & Date:</strong> {data.header.mrir_date}</p>
            <p><strong>Bill No./Dt:</strong> {data.header.bill_no}/{data.header.bill_date}</p>
            <p><strong>Truck No:</strong> {data.header.truck_no}</p>

          </div>
        </div>

        <table className="material-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Item</th>
              <th style={{ textAlign: 'center' }}>Size</th>
              <th>Grade</th>
              <th>Received</th>
              <th style={{ textAlign: 'right' }}>UOM</th>
              <th style={{ textAlign: 'right' }}>Rate</th>
              <th style={{ textAlign: 'right' }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.items.map((item, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{index + 1}</td>
                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{item.item_nm}</td>
                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{item.size_nm}</td>
                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{item.grade}</td>
                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>{item.uom}</td>
                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>{item.received_qty}</td>
                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>{item.rate1}</td>
                <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>{item.amount2}</td>
              </tr>
            ))}
          </tbody>
        </table>

        < div style={{ display: 'flex', justifyContent: 'right' }}>
          <table style={{ width: "40%", textAlign: 'right', marginTop: '1rem', align: 'right' }}>
            <tfoot>
              <tr>
                <td colSpan="2" className="total-text">TOTAL Amount Before Tax</td>
                <td colSpan="2" style={{ textAlign: 'right' }}> {totalAmount}</td>
              </tr>
              {data.charges && data.charges.map((charge, index) => (
                <tr key={index}>
                  <td colSpan="2" className="border">
                    <div> {charge.charge_name} @ {Math.floor(charge.charge_value)} {charge.charge_type === "p" ? "%" : charge.charge_type}</div>
                  </td>
                  <td colSpan="2" className="right border" style={{ textAlign: 'right' }}>{charge.tax_val}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="2" className="total-text">Final Net Amount</td>
                <td colSpan="2" style={{ textAlign: 'right' }}> <strong>{netAmount}</strong> </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <footer>
          <div className="signatures">
            <span>Prepared by</span>
            <span>Stores Incharge</span>
            <span>Deptt Head</span>
            <span>Accounts</span>
            <span>Authority</span>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Receipt;