import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, Button, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetails from "./BasicDetails";
import VDetailPart from "./VDetailPart";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const identifiers = ["VoucherDetail"];

const VoucherNew = (props) => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [allData, setAllData] = useState({
    VoucherHeader: [
      {
        voucher_type: null,
        voucher_date: null,
        account_codeh: null,
        cheque_no: null,
        cheque_date: null,
        cr: null,
        dr: null,
        narration: null,
      },
    ],
    VoucherDetail: [
      {
        entry_type: null,
        account_code: null,
        external_entity_code: null,
        amount: null,
        cost_code: null,
        department_code: null,
        budget_code: null,
      },
    ],
  });
  const [ad, setAD] = useState(null);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);
    axios
      .get(employeeData.URL + "/api/v1/voucher/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            ENTRY_TYPE: {
              fields: [{ name: "KEY" }, { name: "VALUE" }],
              rows: [
                { KEY: "C", VALUE: "Credit" },
                { KEY: "D", VALUE: "Debit" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // Handle error here
      });
  }, []);

  const calculateTotals = (voucherDetails) => {
    let totalCr = 0;
    let totalDr = 0;

    voucherDetails.forEach((detail) => {
      if (detail.entry_type === "C") {
        totalCr += detail.amount ? parseFloat(detail.amount) : 0;
      } else if (detail.entry_type === "D") {
        totalDr += detail.amount ? parseFloat(detail.amount) : 0;
      }
    });

    return { totalCr, totalDr };
  };

  const handleSave = (event) => {
    var formv = true;
    identifiers.forEach((val) => {
      let count = 0;
      allData[val].forEach((data) => {
        if (!data) {
          formv = formv && false;
          if (count === 0) {
            message.error({
              content: "Empty Fields In " + val.toUpperCase() + " Tab!!!",
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });
          }
          count = count + 1;
        }
      });
    });

    let missingFields = [];

    if (allData.VoucherHeader[0].voucher_type == null) {
      missingFields.push("Voucher Type");
    }
    if (allData.VoucherHeader[0].voucher_date == null) {
      missingFields.push("Voucher Date");
    }
    if (allData.VoucherHeader[0].cr == null) {
      missingFields.push("Credit");
    }
    if (allData.VoucherHeader[0].dr == null) {
      missingFields.push("debit");
    }
    if (allData.VoucherHeader[0].narration == null) {
      missingFields.push("narration");
    }

    if (missingFields.length > 0) {
      message.error({
        content: `Please Fill The Mandatory Fields: ${missingFields.join(
          ", "
        )}!!!`,
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return; // Stop further execution if validation fails
    }

    const allFieldsNull = allData.VoucherDetail.every(
      (detail) =>
        detail.entry_type === null &&
        detail.account_code === null &&
        detail.amount === null
    );

    if (allFieldsNull) {
      message.error({
        content: "Please Fill At Least One Row In The Voucher Detail Tab!!!",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return; // Stop further execution if all fields are null
    }

    // //////////////////////with level
    const fieldDisplayNameMap = {
      entry_type: "Entity Type",
      account_code: "Account",
      amount: "Amount",
    };

    const invalidRows = allData.VoucherDetail.filter((detail, index) => {
      const mandatoryFields = ["entry_type", "account_code", "amount"];
      const missingFields = mandatoryFields.filter(
        (field) =>
          detail[field] === null ||
          detail[field] === undefined ||
          detail[field] === ""
      );
      if (missingFields.length > 0) {
        // Map field names to display names
        const displayNames = missingFields.map(
          (field) => fieldDisplayNameMap[field]
        );
        // Construct error message for this row
        const errorMessage = `Row ${
          index + 1
        }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
        // Display error message
        message.error({
          content: errorMessage,
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });

        setLoading(false);
        return true;
      }
      return false;
    });

    if (invalidRows.length > 0) {
      return; // Stop further execution if validation fails
    }

    if (formv) {
      setLoading(true);
      const postData = {
        ...allData,
        VoucherDetail: allData.VoucherDetail.map((dep) => {
          return {
            ...dep,
          };
        }),
      };

      console.log(postData);
      axios
        .post(employeeData.URL + "/api/v1/voucher/create-voucher", postData, {
          withCredentials: true,
          credentials: "include",
        })
        .then((response) => {
          message.success({
            content: "Voucher Created Successfully!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
          history("/finance/transaction/voucher-detail");
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/finance/transaction/voucher-detail");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}

          <Col lg={24} md={24}>
            <BasicDetails
              create={true}
              ad={ad}
              setData={setAllData}
              data={allData.VoucherHeader[0]}
              editMode={editMode}
            />

            <div>
              <Tabs
                centered
                tabPosition="top"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <TabPane tab={<span>Voucher Detail</span>} key="0" type="line">
                  <VDetailPart
                    ad={ad}
                    setData={setAllData}
                    data={allData.VoucherDetail}
                    calculateTotals={calculateTotals}
                    editMode={editMode}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default VoucherNew;
