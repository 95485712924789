import { Row } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";

const BasicDetails = (props) => {
  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["VoucherHeader"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        VoucherHeader: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    props.setData((data) => {
      const newdata = [...data["VoucherHeader"]];
      newdata[0][param] = val;
      return {
        ...data,
        VoucherHeader: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["VoucherHeader"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        VoucherHeader: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="VOUCHER CODE"
            param="voucher_code"
            value={props.data.voucher_code}
          />
        )}
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.VOUCHER_TYPE}
          type="Select"
          name="Voucher Type"
          param="voucher_type"
          required="True"
          placeholder="Select Voucher Type"
          value={props.data.voucher_type}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          name="Voucher Date"
          type="Date"
          param="voucher_date"
          required="True"
          placeholder="Select Voucher Date"
          value={props.data.voucher_date}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Ref Voucher No"
          param="ref_voucher_code"
          placeholder="Enter Ref Voucher No"
          value={props.data.ref_voucher_code}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ACCOUNT_CODEH} type = "Select" name = "Account" param = "account_codeh" value = {props.data.account_codeh}/>   */}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange}  options = {props.ad.ACCOUNT_CODE} type = "Select" name = "Account" param = "account_code" value = {props.data.account_code}/>  */}
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          name="Cheque Date"
          type="Date"
          param="cheque_date"
          placeholder="Select Cheque Date"
          value={props.data.cheque_date}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Cheque No"
          param="cheque_no"
          placeholder="Enter Cheque No"
          value={props.data.cheque_no}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Credit"
          required="True"
          Disable={true}
          //   placeholder="Select Voucher Type"
          param="cr"
          value={props.data.cr}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Debit"
          Disable={true}
          required="True"
          param="dr"
          value={props.data.dr}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Narration"
          param="narration"
          required="True"
          type="textarea"
          value={props.data.narration}
        />

        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleSChange} options = {props.ad.ITEM_HOME_YN} type = "Select" name = "PRIORITY" param = "ITEM_HOME_YN" value = {props.data.item_home_yn}/>           */}
      </Row>
    </div>
  );
};

export default BasicDetails;
