import React, { useState, useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import "./GateComponent.css"; // Ensure this file contains necessary styles

const GateComponent = (props) => {
  console.log(props);
  const [data, setData] = useState(props.pdfResponse);
  const componentRef = useRef();
  const actionRef = useRef();

  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const response = await fetch(`http://localhost:3001/factory-arrival/${arrivalCode}`);
  //             const data = await response.json();
  //             setData(data);
  //         } catch (err) {
  //             console.log('Failed to fetch factory arrival data', err);
  //         }
  //     };
  //     fetchData();
  // }, [arrivalCode]);

  const handlePrint = () => {
    const printContents = componentRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const handleDownloadPdf = () => {
    const element = componentRef.current;
    const actions = actionRef.current;
    if (actions) actions.style.display = "none";

    const options = {
      filename: `Inward.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .set(options)
      .from(element)
      .save()
      .then(() => {
        if (actions) actions.style.display = "block";
      })
      .catch((err) => {
        console.error("Error generating PDF:", err);
        if (actions) actions.style.display = "block";
      });
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  const totalQuantity = data.details.reduce(
    (total, item) => total + parseFloat(item.quantity),
    0
  );

  return (
    <div ref={componentRef}>
      <div className="sale-actions" ref={actionRef}>
        <button onClick={handlePrint}>Print</button>
        <button onClick={handleDownloadPdf}>Download as PDF</button>
      </div>
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          width: "800px",
          margin: "0 auto",
          border: "1px solid black",
        }}
      >
        <h2 style={{ textAlign: "center" }}>
          {data.company.company_name} ({data.address.site_desc})
        </h2>
        <p style={{ textAlign: "center" }}>{data.address.add1}</p>
        <h3
          style={{
            textAlign: "center",
            padding: "10px 0",
            borderTop: "2px solid black",
            borderBottom: "1px solid black",
          }}
        >
          Inward Gate Pass
        </h3>

        <table
          style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
        >
          <tbody>
            <tr>
              <td style={{ paddingLeft: "5px" }}>
                GP Code<strong style={{ paddingLeft: "10px" }}>&#58;</strong>
              </td>
              <td style={{ paddingLeft: "5px" }}>{data.header.gate_pass_no}</td>
              <td style={{ paddingLeft: "5px" }}>
                Challan Date
                <strong style={{ paddingLeft: "10px" }}>&#58;</strong>
              </td>
              <td style={{ paddingLeft: "5px" }}>
                {new Date(data.header.challan_date).toLocaleDateString()}
              </td>
            </tr>
            <tr>
              <td style={{ paddingLeft: "5px" }}>
                GP Date<strong style={{ paddingLeft: "10px" }}>&#58;</strong>
              </td>
              <td style={{ paddingLeft: "5px" }}>
                {new Date(data.header.gate_pass_date).toLocaleDateString()}
              </td>
              <td style={{ paddingLeft: "5px" }}>
                Po No<strong style={{ paddingLeft: "10px" }}>&#58;</strong>
              </td>
              <td style={{ paddingLeft: "5px" }}>{data.header.po_number}</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: "5px" }}>
                Truck No.<strong style={{ paddingLeft: "10px" }}>&#58;</strong>
              </td>
              <td style={{ paddingLeft: "5px" }}>{data.header.truck_no}</td>
              <td style={{ paddingLeft: "5px" }}>
                Party Name<strong style={{ paddingLeft: "10px" }}>&#58;</strong>
              </td>
              <td style={{ paddingLeft: "5px" }}>{data.header.party}</td>
            </tr>
            <tr>
              <td style={{ paddingLeft: "5px" }}>
                Total Wt<strong style={{ paddingLeft: "10px" }}>&#58;</strong>
              </td>
              <td></td>
              <td>
                Tare Wt <strong style={{ paddingLeft: "10px" }}>&#58;</strong>
              </td>
              <td style={{ paddingLeft: "5px" }}></td>
            </tr>
            <tr>
              <td style={{ paddingLeft: "5px" }}>
                Net Wt<strong style={{ paddingLeft: "10px" }}>&#58;</strong>
              </td>
              <td style={{ paddingLeft: "5px" }}></td>
              <td style={{ paddingLeft: "5px" }}></td>
              <td style={{ paddingLeft: "5px" }}></td>
            </tr>
          </tbody>
        </table>

        {/* Dynamic Table */}
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead style={{}}>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Sr. No
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Item Name
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Uom</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Size
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Grade
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            {data.details.map((item, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {index + 1}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.item_name}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.uom_name}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.size_name}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.quality_name}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.quantity}
                </td>
              </tr>
            ))}
          </tbody>

          {/* Total row */}
          <tfoot style={{}}>
            <tr>
              <th
                colSpan="5"
                style={{
                  borderTop: "1px solid #000",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                Total:
              </th>
              <th style={{ borderTop: "1px solid #000" }}>{totalQuantity}</th>
            </tr>
          </tfoot>
        </table>

        {/* Footer */}
        <div
          style={{
            marginTop: "4rem",
            padding: "2rem",
            display: "flex",
            justifyContent: "space-around",
            borderTop: "1px solid black",
          }}
        >
          <div> Prepared By </div>
          <div>Stores Incharge </div>
          <div>Security Incharge</div>
          <div> GM</div>
        </div>
      </div>
    </div>
  );
};

export default GateComponent;
