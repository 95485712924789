import React from "react";
import { Table, Input, Select, message } from "antd";

const { Option } = Select;

const DummyTable = ({
  data,
  onInputChange,
  onSelectChange,
  onDiscountChange,
  onQuantityChage,
}) => {
  // Define columns
  const columns = [
    { title: "Indent Code", dataIndex: "rq_code", key: "rq_code" },
    { title: "Indent Date", dataIndex: "plan_date", key: "plan_date" },
    { title: "Item Name", dataIndex: "item_name", key: "item_name" },
    { title: "Hsn", dataIndex: "hsn", key: "hsn" },
    { title: "Uom", dataIndex: "uom", key: "uom" },
    { title: "Size", dataIndex: "sz", key: "sz" },
    { title: "Grade", dataIndex: "qt", key: "qt" },

    {
      title: "Quantity",
      key: "total_qty",
      align: "right",
      render: (text, record) => (
        <Input
          placeholder="Enter quantity"
          type="number"
          value={record.total_qty}
          onChange={(e) => {
            // Check if total_qty is greater than initial_qty
            if (parseInt(e.target.value) > record.initial_qty) {
              message.warning(
                `Quantity cannot be greater than Initial Quantity (${record.initial_qty})`
              );
            } else {
              onQuantityChage(e.target.value, record.unique_code);
            }
          }}
          style={{ textAlign: "right", width: 100 }}
        />
      ),
    },
    {
      title: "Rate",
      key: "rate",
      align: "right",
      render: (text, record) => (
        <Input
          placeholder="Enter rate"
          value={record.rate}
          min={0}
          type="number"
          onChange={(e) => onInputChange(e.target.value, record.unique_code)}
          style={{ textAlign: "right", width: 100 }}
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (text, record) => (
        <span>{record.total_qty * (record.rate || 0)}</span>
      ),
    },

    {
      title: "Dis On",
      key: "discount_on",
      align: "center",
      render: (text, record) => (
        <Select
          value={record.discount_on}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "discount_on")
          }
          style={{ width: 120 }}
        >
          <Option value="r">Rate</Option>
          <Option value="ta">Total Amt</Option>
        </Select>
      ),
    },

    {
      title: "Discount",
      key: "discount",
      render: (text, record) => (
        <Input
          placeholder="Enter discount"
          value={record.discount}
          onChange={(e) => onDiscountChange(e.target.value, record.unique_code)}
          style={{ width: 100 }}
        />
      ),
    },

    {
      title: "Dis Type",
      key: "dis_type",
      render: (text, record) => (
        <Select
          value={record.dis_type}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "dis_type")
          }
          style={{ width: 120 }}
        >
          <Option value="p">%</Option>
          <Option value="a">A</Option>
        </Select>
      ),
    },

    { title: "Final Rate", dataIndex: "rate1", key: "rate1" },
    { title: "Net Amount", dataIndex: "amount2", key: "amount2" },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.unique_code} // Assuming unique_code is unique for each row
    />
  );
};

export default DummyTable;
