import React from "react";
import { Modal, Table } from "antd";

const ReqCodeModal = ({ visible, onClose, onRowClick, data }) => {
  // const dataSource = [
  //   { key: "1", code: "REQ001", description: "Requisition 1" },
  //   { key: "2", code: "REQ002", description: "Requisition 2" },
  //   { key: "3", code: "REQ003", description: "Requisition 3" },
  // ];

  const columns = [
    {
      title: "Gate Pass No",
      dataIndex: "factory_arrival_code",
      key: "factory_arrival_code",
    },
    { title: "Party", dataIndex: "party", key: "party" },
    { title: "Challan Date", dataIndex: "challan_date", key: "challan_date" },
    { title: "Deal Desc", dataIndex: "deal_desc", key: "deal_desc" },
    // { title: "Dealer", dataIndex: "dealer_name", key: "dealer_name" },

    // { title: "Total Qty", dataIndex: "tot_qty", key: "tot_qty" },
  ];

  return (
    <Modal
      title="List Of Gate Pass's"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={900}
    >
      <Table
        dataSource={data}
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => {
              onRowClick(record);
              onClose();
            },
          };
        }}
      />
    </Modal>
  );
};

export default ReqCodeModal;
