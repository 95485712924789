import { Row, Col, Card, Spin } from "antd"; // Import Spin for loading indicator
import classes from "./Dashboard.module.css";
import DataCards from "./DataCards";
import { useEffect, useContext, useState } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import Charts from "./Charts";
import Charts1 from "./Charts1";
import Charts2 from "./Charts2";
import Charts3 from "./Charts3";

const Dashboard = () => {
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading
  const employeeData = useContext(DataContext);
  // console.log(employeeData);

  useEffect(() => {
    axios
      .get(employeeData.URL + "/api/v1/fin-dash/dash-board", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.data);
        setAnalysis(response.data.data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((err) => {
        console.error(err);
        setLoading(false); // In case of error, also stop loading
      });
  }, []);

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Spin size="large" /> {/* Loading spinner */}
      </Row>
    );
  }

  return (
    <>
      <Row
        gutter={16}
        className={classes["SiteLayoutBackground"]}
        // style={{ border: "2px solid red" }}
        // data={analysis}
      >
        <Col
          lg={{ span: 12, offset: 0 }} // 6-column layout on large screens
          md={{ span: 12 }} // 12-column (half-width) layout on medium screens
          sm={{ span: 24 }} // Full-width on small screens
          xs={{ span: 24 }}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts
              chartType="pie"
              data={analysis.formattedData}
              type={"balance"}
            />
          </Card>
        </Col>

        <Col
          lg={{ span: 12, offset: 0 }} // 6-column layout on large screens
          md={{ span: 12 }} // 12-column (half-width) layout on medium screens
          sm={{ span: 24 }} // Full-width on small screens
          xs={{ span: 24 }}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts1
              chartType="pie"
              data={analysis.formattedDataSize}
              type={"balance"}
            />
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "4px" }}>
        <Col
          lg={{ span: 12, offset: 0 }} // 6-column layout on large screens
          md={{ span: 12 }} // 12-column (half-width) layout on medium screens
          sm={{ span: 24 }} // Full-width on small screens
          xs={{ span: 24 }}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts2
              chartType="pie"
              data={analysis.formattedDataCustomerNew}
              type={"item"}
            />
          </Card>
        </Col>
        <Col
          lg={{ span: 12, offset: 0 }}
          md={24}
          xs={24}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts3
              chartType="pie"
              data={analysis.formattedDataCustomerty}
              type={"size"}
            />
          </Card>
        </Col>
      </Row>
      <p></p>
    </>
  );
};

export default Dashboard;
