import React, { useState } from "react";
import { PieChart, Pie, Tooltip, Cell, LabelList } from "recharts";
import { Select, MenuItem, Box, Typography, Grid } from "@mui/material";

const COLORS = ["#1560BD", "#3a7ca5", "#45b6fe", "#E0E5FA", "#50a5f1"];

const Charts = ({ data, type }) => {
  const transformedData = data.map((item) => ({ ...item, value: 1 })); // Transform data

  return (
    <Box sx={{ width: "100%", maxWidth: 300, margin: "0 auto", padding: 0 }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h7"
            align="center"
            sx={{ fontWeight: "bold", color: "#1560BD" }}
          >
            Slow Moving Items
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ padding: 0, borderRadius: 2, height: 300 }}>
        <PieChart width={250} height={250}>
          <Pie
            data={transformedData}
            dataKey="value"
            nameKey="item"
            cx="50%"
            cy="50%"
            outerRadius={80}
            label={({ name, value }) => `${name}: ${value}`}
          >
            {transformedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                return (
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "5px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <strong>{payload[0].name}</strong>: {payload[0].value}
                  </div>
                );
              }
              return null;
            }}
          />
        </PieChart>
      </Box>
    </Box>
  );
};

export default Charts;
