import React, { useState, useEffect, useRef } from 'react';
import html2pdf from 'html2pdf.js';
import './Outward.css'; // Ensure this file contains necessary styles

const Outward = (props) => {
    const [data, setData] = useState(props.pdfResponse);
    const componentRef = useRef();
    const actionRef = useRef();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`http://localhost:3001/gate-out/${gpCode}`);
    //             const data = await response.json();
    //             setData(data);
    //         } catch (err) {
    //             console.log('Failed to fetch factory arrival data', err);
    //         }
    //     };
    //     fetchData();
    // }, [gpCode]);

    const handlePrint = () => {
        const printContents = componentRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();
    };

    const handleDownloadPdf = () => {
        const element = componentRef.current;
        const actions = actionRef.current;
        if (actions) actions.style.display = 'none';

        const options = {
            filename: `outwarrd.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        html2pdf()
            .set(options)
            .from(element)
            .save()
            .then(() => {
                if (actions) actions.style.display = 'block';
            })
            .catch((err) => {
                console.error('Error generating PDF:', err);
                if (actions) actions.style.display = 'block';
            });
    };

    if (!data) {
        return <p>Loading...</p>;
    }

    const totalQuantity = data.details.reduce((total, item) => total + parseFloat(item.quantity), 0);

    return (
        <div  ref={componentRef}>
            <div className="sale-actions" ref={actionRef}>
                <button onClick={handlePrint}>Print</button>
                <button onClick={handleDownloadPdf}>Download as PDF</button>
            </div>
            <div style={{  fontFamily: 'Arial, sans-serif', width: '800px', margin: '0 auto', border: '1px solid black' }}>
                <h2 style={{ textAlign: 'center' }}>{data.company.company_name} ({data.address.site_desc})</h2>
                <p style={{ textAlign: 'center' }}>{data.address.add1}</p>
                <h3 style={{ textAlign: 'center',padding:'10px 0', borderTop:'2px solid black',borderBottom:'1px solid black' }}>Outward Gate Pass</h3>

                <table style={{ width: '100%', marginBottom: '20px', marginTop:'20px' }}>
                    <tbody>
                        <tr >
                            <td style={{paddingLeft:'5px'}}>GP Code<strong style={{paddingLeft:'10px'}}>&#58;</strong></td>
                            <td style={{paddingLeft:'5px'}}>{data.header.gp_code}</td>
                            <td style={{paddingLeft:'5px'}}>Invoice<strong style={{paddingLeft:'10px'}}>&#58;</strong></td>
                            <td style={{paddingLeft:'5px'}}>{new Date(data.header.challan_date).toLocaleDateString()}</td>
                           
                        </tr>
                        <tr>
                            <td style={{paddingLeft:'5px'}}>GP Date<strong style={{paddingLeft:'10px'}}>&#58;</strong></td>
                            <td style={{paddingLeft:'5px'}}>{new Date(data.header.gp_date).toLocaleDateString()}</td>
                            <td style={{paddingLeft:'5px'}}>Invoice Code<strong style={{paddingLeft:'10px'}}>&#58;</strong></td>
                            <td style={{paddingLeft:'5px'}}>{data.header.invoice_code}</td>
                        </tr>
                        <tr>
                            <td style={{paddingLeft:'5px'}}>Gate Slip No<strong style={{paddingLeft:'10px'}}>&#58;</strong></td>
                            <td style={{paddingLeft:'5px'}}>{data.header.gateslip_no}</td>
                            <td style={{paddingLeft:'5px'}}>Truck No.<strong style={{paddingLeft:'10px'}}>&#58;</strong></td>
                            <td style={{paddingLeft:'5px'}}>{data.header.truck_no}</td>
                            
                        </tr>
                       
                        <tr>
                            
                            <td style={{paddingLeft:'5px'}}>Customer<strong style={{paddingLeft:'10px'}}>&#58;</strong></td>
                            <td style={{paddingLeft:'5px'}}>{data.header.customer}</td>
                            {/* <td style={{paddingLeft:'5px'}}>Challan Date<strong style={{paddingLeft:'10px'}}>&#58;</strong></td> */}
                            {/* <td style={{paddingLeft:'5px'}}>{new Date(data.header.challan_date).toLocaleDateString()}</td> */}
                            
                        </tr>
                        
                    </tbody>
                </table>

                {/* Dynamic Table */}
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead style={{ }}>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px' ,textAlign:'right'}}>Sr. No</th>
                            <th style={{ border: '1px solid black', padding: '8px',textAlign:'right'}}>Item Name</th>
                            <th style={{ border: '1px solid black', padding: '8px',textAlign:'right' }}>Uom</th>
                            <th style={{ border: '1px solid black', padding: '8px',textAlign:'right' }}>Size</th>
                            <th style={{ border: '1px solid black', padding: '8px' ,textAlign:'right'}}>Grade</th>
                            <th style={{ border: '1px solid black', padding: '8px',textAlign:'right' }}>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.details.map((item, index) => (
                            <tr key={index}>
                                <td style={{ border: '1px solid black', padding: '8px',textAlign:'right' }}>{index + 1}</td>
                                <td style={{ border: '1px solid black', padding: '8px',textAlign:'right' }}>{item.item}</td>
                                <td style={{ border: '1px solid black', padding: '8px',textAlign:'right' }}>{item.uom_name}</td>
                                <td style={{ border: '1px solid black', padding: '8px',textAlign:'right' }}>{item.size}</td>
                                <td style={{ border: '1px solid black', padding: '8px' ,textAlign:'right'}}>{item.grade}</td>
                                <td style={{ border: '1px solid black', padding: '8px',textAlign:'right' }}>{item.quantity}</td>
                            </tr>
                        ))}
                    </tbody>

                    {/* Total row */}
                    <tfoot style={{ }}>
                        <tr >
                            <th colSpan="5" style={{ borderTop: '1px solid #000', textAlign: 'right', paddingRight: '10px' }}>Total:</th >
                            <th style={{ borderTop: '1px solid #000' }} >{totalQuantity}</th >
                        </tr >
                    </tfoot >
                </table>

                {/* Footer */}
                <div style={{ marginTop: '4rem' , padding:'2rem' , display:'flex', justifyContent:'space-around', borderTop:'1px solid black' }}>
                   <div> Prepared By </div> 
                    <div>Stores Incharge </div> 
                    <div>Security Incharge</div> 
                    <div> GM</div>
                 </div >
            </div>
            </div>

            );
          };
          
          export default Outward;
